var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "단지안내",
            "tabActive": "근린생활시설 평면도",
            "bg": "/images/sub/complex/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("단지안내")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "titWrapClass": "text-center"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("근린생활시설 평면도")];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "page-section__content"
  }, [_c('v-tabs', {
    staticClass: "v-tabs--density-compact",
    attrs: {
      "grow": "",
      "hide-slider": "",
      "centered": ""
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('tab-quarternary', [_vm._v("지하 2층")]), _c('tab-quarternary', [_vm._v("지상 1층")]), _c('tab-quarternary', [_vm._v("지상 2층")])], 1)], 1), _c('div', {
    staticClass: "page-section__content"
  }, [_c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', {
    attrs: {
      "eager": ""
    }
  }, [_c('sheet-secondary', {
    staticClass: "py-md-106px",
    attrs: {
      "align": "center"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/complex/neighborhood/b1.jpg",
      "alt": ""
    }
  })])], 1), _c('v-tab-item', {
    attrs: {
      "eager": ""
    }
  }, [_c('sheet-secondary', {
    staticClass: "py-md-106px",
    attrs: {
      "align": "center"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/complex/neighborhood/f1.jpg",
      "alt": ""
    }
  })])], 1), _c('v-tab-item', {
    attrs: {
      "eager": ""
    }
  }, [_c('sheet-secondary', {
    staticClass: "py-md-106px",
    attrs: {
      "align": "center"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/complex/neighborhood/f2.jpg",
      "alt": ""
    }
  })])], 1)], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }