<template>
    <v-dialog v-model="show" max-width="588">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>

        <v-card tile>
            <v-card-title class="d-block px-md-40px py-md-40px">
                <txt class="font-weight-bold secondary--text text-center mb-12px mb-md-16px">Lumino816</txt>
                <tit class="text-center">루미노816 청약신청 접수/조회</tit>
            </v-card-title>
            <v-card-text class="px-md-40px pb-md-40px">
                <v-sheet color="grey lighten-5 d-flex justify-center align-center pa-16px pa-md-24px">
                    <txt>접수이력이 있다면, 조회 페이지로 이동합니다.</txt>
                </v-sheet>
            </v-card-text>
            <v-card-actions class="justify-center px-md-40px pb-md-40px">
                <v-btn tile x-large color="secondary" class="v-btn--width-fixed" @click="certify">
                    <span class="font-size-14 font-size-lg-16">본인인증</span>
                </v-btn>

                <kcp-cert ref="kcp-cert" @input="verify" />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import CryptoAes from "@/plugins/crypto-aes";
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";
import KcpCert from "@/components/plugins/kcp/kcp-cert.vue";
import { mapActions } from "vuex";

export default {
    components: {
        Tit,
        Txt,
        KcpCert,
    },
    data() {
        return {
            show: false,
        };
    },
    methods: {
        ...mapActions(["setCi"]),
        certify() {
            this.$refs["kcp-cert"].open();
        },
        async verify({ ci, _certification }) {
            try {
                const today = this.$dayjs();
                const open = this.$dayjs("2024-10-03 00:00:00");
                const deadline = this.$dayjs("2024-10-06 23:59:59");

                ci = CryptoAes.encrypt(ci);
                let { applications } = await api.v1.application.gets({
                    params: { ci },
                });

                if (applications.length > 1) throw new Error(`중복된 폼이 존재합니다.\n관리자로 문의해주세요.`);

                this.setCi(ci);
                // tempBlock
                if (today.isBefore(open)) throw new Error("10월 3일 00시부터 접수 가능합니다.");

                if (applications.length) {
                    this.$router.push(`/application/inquiry/${applications[0]._id}`);
                } else {
                    if (today.isAfter(deadline)) throw new Error("접수는 10월 6일 23시 59분까지만 가능합니다.");
                    this.$router.push({ path: "/form/application", query: { _certification } });
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
};
</script>