<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="청약안내" tabActive="모집공고문" bg="/images/sub/subscription/visual.jpg">
                <template #title>청약안내</template>
            </sub-head-primary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first page-section--last">
            <template #tit>모집공고</template>

            <v-img src="/images/sub/business/overview/overview.jpg" />

            <div class="btn-wrap btn-wrap--lg">
                <btn-primary x-large href="/assets/document/recruitment.pdf" download="연신내역 청년안심주택 Lumino816 입주자 모집공고(공공지원민간임대).pdf">모집공고 다운로드<v-img class="ml-16px ml-lg-24px" src="/images/icon/icon-download-white.png" /></btn-primary>
            </div>
        </page-section-primary>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import btnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        btnPrimary,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
