var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "사업안내",
            "tabActive": "공급안내",
            "bg": "/images/sub/business/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("사업안내")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "titWrapClass": "text-center"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("공급안내")];
      },
      proxy: true
    }])
  }, [_c('sheet-tertiary', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('chip-primary', {
    staticClass: "w-md-140px"
  }, [_vm._v("공급위치")])], 1), _c('v-col', [_c('txt-quarternary', [_vm._v("서울특별시 은평구 불광동 302-13번지 일원 (3호선, 6호선 연신내역 3번 출구)")])], 1)], 1), _c('v-row', {
    staticClass: "row--lg mt-0",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('chip-primary', {
    staticClass: "w-md-140px"
  }, [_vm._v("공급규모")])], 1), _c('v-col', [_c('txt-quarternary', [_vm._v("공동주택 지하3층 ~ 22층")])], 1)], 1), _c('v-row', {
    staticClass: "row--lg mt-0",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('chip-primary', {
    staticClass: "w-md-140px"
  }, [_vm._v("공급대상")])], 1), _c('v-col', [_c('txt-quarternary', [_vm._v("민간임대 180세대 (특별공급 36세대, 일반공급 144세대)")])], 1)], 1)], 1), _c('div', {
    staticClass: "v-data-table v-data-table--border v-data-table--line v-data-table--density-comfortable mt-40px mt-md-60px"
  }, [_c('div', {
    staticClass: "v-data-table__wrapper"
  }, [_c('table', [_c('thead', [_c('tr', [_c('th-primary', {
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("구분")]), _c('th-primary', {
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("주택"), _c('br'), _vm._v("타입")]), _c('th-primary', {
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("공급"), _c('br'), _vm._v("호수")]), _c('th-primary', {
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("특별"), _c('br'), _vm._v("공급")]), _c('th-primary', {
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("일반"), _c('br'), _vm._v("공급")]), _c('th-primary', {
    attrs: {
      "colspan": "4"
    }
  }, [_vm._v("세대당 계약면적(㎡)")]), _c('th-primary', {
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("발코니"), _c('br'), _vm._v("확장면적"), _c('br'), _vm._v("(㎡)")])], 1), _c('tr', [_c('th-secondary', {
    staticClass: "border-r-white"
  }, [_vm._v("주거전용")]), _c('th-secondary', {
    staticClass: "border-r-white"
  }, [_vm._v("주거공용")]), _c('th-secondary', {
    staticClass: "border-r-white"
  }, [_vm._v("기타공용")]), _c('th-secondary', [_vm._v("합계")])], 1)]), _c('tbody', [_c('tr', {
    staticClass: "table-head-divider"
  }, [_c('th-secondary', {
    attrs: {
      "rowspan": "3"
    }
  }, [_vm._v("청년형(1인)")]), _c('td-primary', [_vm._v("17.65㎡"), _c('br'), _vm._v("(A)")]), _c('td-primary', [_vm._v("54")]), _c('td-primary', [_vm._v("11")]), _c('td-primary', [_vm._v("43")]), _c('td-primary', [_vm._v("17.65")]), _c('td-primary', [_vm._v("12.70")]), _c('td-primary', [_vm._v("4.61")]), _c('td-primary', [_vm._v("34.96")]), _c('td-primary', [_vm._v("6.82")])], 1), _c('tr', [_c('td-primary', [_vm._v("17.02㎡"), _c('br'), _vm._v("(D)")]), _c('td-primary', [_vm._v("45")]), _c('td-primary', [_vm._v("9")]), _c('td-primary', [_vm._v("36")]), _c('td-primary', [_vm._v("17.02")]), _c('td-primary', [_vm._v("12.24")]), _c('td-primary', [_vm._v("4.44")]), _c('td-primary', [_vm._v("33.70")]), _c('td-primary', [_vm._v("4.77")])], 1), _c('tr', {
    staticClass: "table-divider"
  }, [_c('td-primary', [_vm._v("17.54㎡"), _c('br'), _vm._v("(E)")]), _c('td-primary', [_vm._v("10")]), _c('td-primary', [_vm._v("2")]), _c('td-primary', [_vm._v("8")]), _c('td-primary', [_vm._v("17.54")]), _c('td-primary', [_vm._v("12.56")]), _c('td-primary', [_vm._v("4.58")]), _c('td-primary', [_vm._v("34.68")]), _c('td-primary', [_vm._v("6.59")])], 1), _c('tr', [_c('th-secondary', {
    attrs: {
      "rowspan": "4"
    }
  }, [_vm._v("신혼부부형"), _c('br'), _vm._v("(예비신혼 포함)")]), _c('td-primary', [_vm._v("35.84㎡"), _c('br'), _vm._v("(B)")]), _c('td-primary', [_vm._v("44")]), _c('td-primary', [_vm._v("9")]), _c('td-primary', [_vm._v("35")]), _c('td-primary', [_vm._v("35.84")]), _c('td-primary', [_vm._v("24.78")]), _c('td-primary', [_vm._v("9.35")]), _c('td-primary', [_vm._v("69.97")]), _c('td-primary', [_vm._v("8.42")])], 1), _c('tr', [_c('td-primary', [_vm._v("35.14㎡"), _c('br'), _vm._v("(C)")]), _c('td-primary', [_vm._v("23")]), _c('td-primary', [_vm._v("5")]), _c('td-primary', [_vm._v("18")]), _c('td-primary', [_vm._v("35.14")]), _c('td-primary', [_vm._v("24.07")]), _c('td-primary', [_vm._v("9.17")]), _c('td-primary', [_vm._v("68.38")]), _c('td-primary', [_vm._v("9.39")])], 1), _c('tr', [_c('td-primary', [_vm._v("36.39㎡"), _c('br'), _vm._v("(G)")]), _c('td-primary', [_vm._v("2")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("2")]), _c('td-primary', [_vm._v("36.39")]), _c('td-primary', [_vm._v("25.45")]), _c('td-primary', [_vm._v("9.50")]), _c('td-primary', [_vm._v("71.34")]), _c('td-primary', [_vm._v("5.87")])], 1), _c('tr', [_c('td-primary', [_vm._v("39.03㎡"), _c('br'), _vm._v("(H)")]), _c('td-primary', [_vm._v("2")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("2")]), _c('td-primary', [_vm._v("39.03")]), _c('td-primary', [_vm._v("26.82")]), _c('td-primary', [_vm._v("10.19")]), _c('td-primary', [_vm._v("76.04")]), _c('td-primary', [_vm._v("8.90")])], 1), _c('tr', [_c('th', {
    staticClass: "text-center",
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("합계")]), _c('td-primary', [_vm._v("180")]), _c('td-primary', [_vm._v("36")]), _c('td-primary', [_vm._v("144")]), _c('td-primary'), _c('td-primary'), _c('td-primary'), _c('td-primary'), _c('td-primary')], 1)])])])]), _c('sheet-primary', {
    staticClass: "mt-40px mt-lg-60px"
  }, [_c('txt-quarternary', {
    staticClass: "grey--text text--lighten-1 line-height-17"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "dot"
  }, [_vm._v("본 주택은 공급유형별(청년, 신혼부부) 및 주택타입별로 나뉘며, 청약 신청 시 공급유형별(청년 특별공급 및 일반공급, 신혼부부 특별공급 및 일반공급) 및 주택타입별로 청약접수가 진행되오니, 청약 신청 시 착오 없으시길 바랍니다.")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "dot"
  }, [_vm._v("본 주택에 청약 시 특별공급과 일반공급 중 주택형 별로 1건만 가능하며, 신혼부부(예비 신혼부부 포함)는 1세대로 간주하여 부부(예비부부) 중1 명이 대표로 1건만 신청이 가능하고, 두 명이 각각 중복 신청할 경우에는 전부 무효 처리됩니다.")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "dot"
  }, [_vm._v("본 주택은 인터넷 청약 시, 주거 전용(타입) 별로 구분되어 청약접수가 진행되오니, 청약 신청 시 착오 없으시길 바랍니다.")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "dot"
  }, [_vm._v("청약에 적용되는 주택형(타입) 명은 건축 허가 도서에 표기된 주택형(타입) 명과 다를 수 있습니다.")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "dot"
  }, [_vm._v("주거전용면적은 주거의 용도로만 쓰이는 면적이며, 주거공용면적은 계단, 복도, 공동현관 등 공동주택의 공용면적입니다. 또한 그 밖의 공용면적은 공급 면적을 제외한 공용면적으로, 관리사무소, 경비실, 주민공동시설, 기계·전기실 등이 포함되며, 상기 계약면적에는 위에 설명드린 주거전용면적, 주거공용 면적, 기타 공용 면적, 지하주차장 등의 면적이 포함되어 있습니다.")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "dot"
  }, [_vm._v("본 주택은 주거 목적 외 다른 목적으로 사용이 불가하며, 상기 타입별 공급면적 및 대지면적은 준공 시 일부 변경(±3% 내외) 될 수 있습니다.")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "dot"
  }, [_vm._v("상기 면적은 준공 과정에 경미하게 변경될 수 있으며, 이를 이유로 이의를 제기할 수 없습니다.")])])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }