<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="사업안내" tabActive="공급안내" bg="/images/sub/business/visual.jpg">
                <template #title>사업안내</template>
            </sub-head-primary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first page-section--last">
            <template #tit>공급안내</template>

            <sheet-tertiary>
                <v-row class="row--lg" align="center">
                    <v-col cols="auto">
                        <chip-primary class="w-md-140px">공급위치</chip-primary>
                    </v-col>
                    <v-col>
                        <txt-quarternary>서울특별시 은평구 불광동 302-13번지 일원 (3호선, 6호선 연신내역 3번 출구)</txt-quarternary>
                    </v-col>
                </v-row>
                <v-row class="row--lg mt-0" align="center">
                    <v-col cols="auto">
                        <chip-primary class="w-md-140px">공급규모</chip-primary>
                    </v-col>
                    <v-col>
                        <txt-quarternary>공동주택 지하3층 ~ 22층</txt-quarternary>
                    </v-col>
                </v-row>
                <v-row class="row--lg mt-0" align="center">
                    <v-col cols="auto">
                        <chip-primary class="w-md-140px">공급대상</chip-primary>
                    </v-col>
                    <v-col>
                        <txt-quarternary>민간임대 180세대 (특별공급 36세대, 일반공급 144세대)</txt-quarternary>
                    </v-col>
                </v-row>
            </sheet-tertiary>

            <div class="v-data-table v-data-table--border v-data-table--line v-data-table--density-comfortable mt-40px mt-md-60px">
                <div class="v-data-table__wrapper">
                    <table>
                        <thead>
                            <tr>
                                <th-primary rowspan="2">구분</th-primary>
                                <th-primary rowspan="2">주택<br />타입</th-primary>
                                <th-primary rowspan="2">공급<br />호수</th-primary>
                                <th-primary rowspan="2">특별<br />공급</th-primary>
                                <th-primary rowspan="2">일반<br />공급</th-primary>
                                <th-primary colspan="4">세대당 계약면적(㎡)</th-primary>
                                <th-primary rowspan="2">발코니<br />확장면적<br />(㎡)</th-primary>
                                <!-- <th-primary rowspan="2">실사용<br />면적</th-primary> -->
                            </tr>
                            <tr>
                                <th-secondary class="border-r-white">주거전용</th-secondary>
                                <th-secondary class="border-r-white">주거공용</th-secondary>
                                <th-secondary class="border-r-white">기타공용</th-secondary>
                                <th-secondary>합계</th-secondary>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="table-head-divider">
                                <th-secondary rowspan="3">청년형(1인)</th-secondary>
                                <td-primary>17.65㎡<br />(A)</td-primary>
                                <td-primary>54</td-primary>
                                <td-primary>11</td-primary>
                                <td-primary>43</td-primary>
                                <td-primary>17.65</td-primary>
                                <td-primary>12.70</td-primary>
                                <td-primary>4.61</td-primary>
                                <td-primary>34.96</td-primary>
                                <td-primary>6.82</td-primary>
                                <!-- <td-primary>24.47</td-primary> -->
                            </tr>
                            <tr>
                                <td-primary>17.02㎡<br />(D)</td-primary>
                                <td-primary>45</td-primary>
                                <td-primary>9</td-primary>
                                <td-primary>36</td-primary>
                                <td-primary>17.02</td-primary>
                                <td-primary>12.24</td-primary>
                                <td-primary>4.44</td-primary>
                                <td-primary>33.70</td-primary>
                                <td-primary>4.77</td-primary>
                                <!-- <td-primary>21.79</td-primary> -->
                            </tr>
                            <tr class="table-divider">
                                <td-primary>17.54㎡<br />(E)</td-primary>
                                <td-primary>10</td-primary>
                                <td-primary>2</td-primary>
                                <td-primary>8</td-primary>
                                <td-primary>17.54</td-primary>
                                <td-primary>12.56</td-primary>
                                <td-primary>4.58</td-primary>
                                <td-primary>34.68</td-primary>
                                <td-primary>6.59</td-primary>
                                <!-- <td-primary>24.13</td-primary> -->
                            </tr>
                            <tr>
                                <th-secondary rowspan="4">신혼부부형<br />(예비신혼 포함)</th-secondary>
                                <td-primary>35.84㎡<br />(B)</td-primary>
                                <td-primary>44</td-primary>
                                <td-primary>9</td-primary>
                                <td-primary>35</td-primary>
                                <td-primary>35.84</td-primary>
                                <td-primary>24.78</td-primary>
                                <td-primary>9.35</td-primary>
                                <td-primary>69.97</td-primary>
                                <td-primary>8.42</td-primary>
                                <!-- <td-primary>44.26</td-primary> -->
                            </tr>
                            <tr>
                                <td-primary>35.14㎡<br />(C)</td-primary>
                                <td-primary>23</td-primary>
                                <td-primary>5</td-primary>
                                <td-primary>18</td-primary>
                                <td-primary>35.14</td-primary>
                                <td-primary>24.07</td-primary>
                                <td-primary>9.17</td-primary>
                                <td-primary>68.38</td-primary>
                                <td-primary>9.39</td-primary>
                                <!-- <td-primary>44.53</td-primary> -->
                            </tr>
                            <tr>
                                <td-primary>36.39㎡<br />(G)</td-primary>
                                <td-primary>2</td-primary>
                                <td-primary>-</td-primary>
                                <td-primary>2</td-primary>
                                <td-primary>36.39</td-primary>
                                <td-primary>25.45</td-primary>
                                <td-primary>9.50</td-primary>
                                <td-primary>71.34</td-primary>
                                <td-primary>5.87</td-primary>
                                <!-- <td-primary>42.26</td-primary> -->
                            </tr>
                            <tr>
                                <td-primary>39.03㎡<br />(H)</td-primary>
                                <td-primary>2</td-primary>
                                <td-primary>-</td-primary>
                                <td-primary>2</td-primary>
                                <td-primary>39.03</td-primary>
                                <td-primary>26.82</td-primary>
                                <td-primary>10.19</td-primary>
                                <td-primary>76.04</td-primary>
                                <td-primary>8.90</td-primary>
                                <!-- <td-primary>47.93</td-primary> -->
                            </tr>
                            <tr>
                                <th colspan="2" class="text-center">합계</th>
                                <td-primary>180</td-primary>
                                <td-primary>36</td-primary>
                                <td-primary>144</td-primary>
                                <td-primary></td-primary>
                                <td-primary></td-primary>
                                <td-primary></td-primary>
                                <td-primary></td-primary>
                                <td-primary></td-primary>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <sheet-primary class="mt-40px mt-lg-60px">
                <txt-quarternary class="grey--text text--lighten-1 line-height-17">
                    <v-row>
                        <v-col cols="12"><p class="dot">본 주택은 공급유형별(청년, 신혼부부) 및 주택타입별로 나뉘며, 청약 신청 시 공급유형별(청년 특별공급 및 일반공급, 신혼부부 특별공급 및 일반공급) 및 주택타입별로 청약접수가 진행되오니, 청약 신청 시 착오 없으시길 바랍니다.</p></v-col>
                        <v-col cols="12"><p class="dot">본 주택에 청약 시 특별공급과 일반공급 중 주택형 별로 1건만 가능하며, 신혼부부(예비 신혼부부 포함)는 1세대로 간주하여 부부(예비부부) 중1 명이 대표로 1건만 신청이 가능하고, 두 명이 각각 중복 신청할 경우에는 전부 무효 처리됩니다.</p></v-col>
                        <v-col cols="12"><p class="dot">본 주택은 인터넷 청약 시, 주거 전용(타입) 별로 구분되어 청약접수가 진행되오니, 청약 신청 시 착오 없으시길 바랍니다.</p></v-col>
                        <v-col cols="12"><p class="dot">청약에 적용되는 주택형(타입) 명은 건축 허가 도서에 표기된 주택형(타입) 명과 다를 수 있습니다.</p></v-col>
                        <v-col cols="12"><p class="dot">주거전용면적은 주거의 용도로만 쓰이는 면적이며, 주거공용면적은 계단, 복도, 공동현관 등 공동주택의 공용면적입니다. 또한 그 밖의 공용면적은 공급 면적을 제외한 공용면적으로, 관리사무소, 경비실, 주민공동시설, 기계·전기실 등이 포함되며, 상기 계약면적에는 위에 설명드린 주거전용면적, 주거공용 면적, 기타 공용 면적, 지하주차장 등의 면적이 포함되어 있습니다.</p></v-col>
                        <v-col cols="12"><p class="dot">본 주택은 주거 목적 외 다른 목적으로 사용이 불가하며, 상기 타입별 공급면적 및 대지면적은 준공 시 일부 변경(±3% 내외) 될 수 있습니다.</p></v-col>
                        <v-col cols="12"><p class="dot">상기 면적은 준공 과정에 경미하게 변경될 수 있으며, 이를 이유로 이의를 제기할 수 없습니다.</p></v-col>
                    </v-row>
                </txt-quarternary>
            </sheet-primary>
        </page-section-primary>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import SheetPrimary from "@/components/publish/parents/sheets/sheet-primary.vue";
import SheetTertiary from "@/components/publish/parents/sheets/sheet-tertiary.vue";
import ChipPrimary from "@/components/publish/parents/chips/chip-primary.vue";
import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";
import TxtQuarternary from "@/components/publish/parents/typography/txt-quarternary.vue";
import ThPrimary from "@/components/publish/parents/tables/th-primary.vue";
import ThSecondary from "@/components/publish/parents/tables/th-secondary.vue";
import TdPrimary from "@/components/publish/parents/tables/td-primary.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        SheetPrimary,
        SheetTertiary,
        ChipPrimary,
        TxtPrimary,
        TxtQuarternary,
        ThPrimary,
        ThSecondary,
        TdPrimary,
    },
    data() {
        return {
            headers: [
                { text: "Title1", value: "Title1", width: "10%" },
                { text: "Title2", value: "Title2", width: "15%" },
                { text: "Title3", value: "Title3", width: "20%" },
            ],
            items: [
                { Title1: "Text1-1", Title2: "Text1-2", Title3: "Text1-3" },
                { Title1: "Text2-1", Title2: "Text2-2", Title3: "Text2-3" },
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
