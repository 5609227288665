var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "단지안내",
            "tabActive": "호수 배치도",
            "bg": "/images/sub/complex/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("단지안내")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "titWrapClass": "text-center"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("호수 배치도")];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "page-section__content"
  }, [_c('sheet-tertiary', [_c('div', [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('chip-quarternary', {
    staticClass: "w-md-140px justify-center white--text"
  }, [_vm._v("청년형")])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6",
      "xl": "4"
    }
  }, [_c('chip-quarternary', {
    attrs: {
      "color": "secondary lighten-4"
    }
  }, [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("A TYPE(17.65) 100세대 중 54세대")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6",
      "xl": "4"
    }
  }, [_c('chip-quarternary', {
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("D TYPE(17.02) 80세대 중 45세대")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6",
      "xl": "4"
    }
  }, [_c('chip-quarternary', {
    attrs: {
      "color": "tertiary lighten-4"
    }
  }, [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("E TYPE(17.54) 19세대 중 10세대")])])], 1)], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "pt-18px pt-md-24px"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('chip-quarternary', {
    staticClass: "w-md-140px justify-center white--text"
  }, [_vm._v("신혼부부형")])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6",
      "xl": "4"
    }
  }, [_c('chip-quarternary', {
    attrs: {
      "color": "#FFCA99"
    }
  }, [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("B TYPE(35.84) 58세대 중 44세대")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6",
      "xl": "4"
    }
  }, [_c('chip-quarternary', {
    attrs: {
      "color": "#FFECFA"
    }
  }, [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("C TYPE(35.14) 32세대 중 23세대")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6",
      "xl": "4"
    }
  }, [_c('chip-quarternary', {
    attrs: {
      "color": "#F0A7F1"
    }
  }, [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("G TYPE(36.39) 2세대 중 2세대")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "6",
      "xl": "4"
    }
  }, [_c('chip-quarternary', {
    attrs: {
      "color": "#FFDA57"
    }
  }, [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("H TYPE(39.03) 2세대 중 2세대")])])], 1)], 1)], 1)], 1)], 1)])], 1), _c('div', {
    staticClass: "page-section__content"
  }, [_c('div', {
    staticClass: "v-data-table v-data-table--line v-data-table--density-compact"
  }, [_c('div', {
    staticClass: "v-data-table__wrapper"
  }, [_c('table', [_c('thead', [_c('tr', [_c('th-primary', [_vm._v("층별")]), _c('th-primary', [_vm._v("1호")]), _c('th-primary', [_vm._v("2호")]), _c('th-primary', [_vm._v("3호")]), _c('th-primary', [_vm._v("4호")]), _c('th-primary', [_vm._v("5호")]), _c('th-primary', [_vm._v("6호")]), _c('th-primary', [_vm._v("7호")]), _c('th-primary', [_vm._v("8호")]), _c('th-primary', [_vm._v("9호")]), _c('th-primary', [_vm._v("10호")]), _c('th-primary', [_vm._v("11호")]), _c('th-primary', [_vm._v("12호")]), _c('th-primary', [_vm._v("13호")]), _c('th-primary', [_vm._v("14호")]), _c('th-primary', [_vm._v("15호")]), _c('th-primary', [_vm._v("16호")]), _c('th-primary', [_vm._v("17호")])], 1)]), _c('tbody', [_c('tr', [_c('th-secondary', [_vm._v("22층")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("2202")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("2204")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("2207")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("2208")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("2209")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("2210")]), _c('td-primary', {
    staticClass: "bg-c1"
  }, [_vm._v("2211")]), _c('td-primary', {
    staticClass: "bg-c1"
  }, [_vm._v("2212")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("2213")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("2214")]), _c('td-primary', {
    staticClass: "empty"
  }), _c('td-primary', {
    staticClass: "empty"
  }), _c('td-primary', {
    staticClass: "empty"
  })], 1), _c('tr', [_c('th-secondary', [_vm._v("21층")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("2104")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("2110")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("2111")]), _c('td-primary', {
    staticClass: "bg-c1"
  }, [_vm._v("2112")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("2015")]), _c('td-primary', {
    staticClass: "empty"
  }), _c('td-primary', {
    staticClass: "empty"
  })], 1), _c('tr', [_c('th-secondary', [_vm._v("20층")]), _c('td-primary', {
    staticClass: "bg-e"
  }, [_vm._v("2001")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("2002")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("2004")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("2006")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("2009")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("2010")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("2011")]), _c('td-primary', {
    staticClass: "bg-c1"
  }, [_vm._v("2012")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("2014")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "empty"
  }), _c('td-primary', {
    staticClass: "empty"
  })], 1), _c('tr', [_c('th-secondary', [_vm._v("19층")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("1902")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("1904")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("1905")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("1906")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("1907")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("1910")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-c1"
  }, [_vm._v("1913")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("1914")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("1915")]), _c('td-primary', {
    staticClass: "empty"
  }), _c('td-primary', {
    staticClass: "empty"
  })], 1), _c('tr', [_c('th-secondary', [_vm._v("18층")]), _c('td-primary', {
    staticClass: "bg-e"
  }, [_vm._v("1801")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("1807")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("1808")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("1811")]), _c('td-primary', {
    staticClass: "bg-c1"
  }, [_vm._v("1812")]), _c('td-primary', {
    staticClass: "bg-c1"
  }, [_vm._v("1813")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "empty"
  }), _c('td-primary', {
    staticClass: "empty"
  })], 1), _c('tr', [_c('th-secondary', [_vm._v("17층")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("1703")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("1705")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("1706")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("1708")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("1709")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("1710")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("1711")]), _c('td-primary', {
    staticClass: "bg-c1"
  }, [_vm._v("1712")]), _c('td-primary', {
    staticClass: "bg-c1"
  }, [_vm._v("1713")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("1714")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "empty"
  }), _c('td-primary', {
    staticClass: "empty"
  })], 1), _c('tr', [_c('th-secondary', [_vm._v("16층")]), _c('td-primary', {
    staticClass: "bg-e"
  }, [_vm._v("1601")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("1602")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("1603")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("1604")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("1606")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("1607")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("1608")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("1609")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("1610")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("1611")]), _c('td-primary', {
    staticClass: "bg-c1"
  }, [_vm._v("1612")]), _c('td-primary', {
    staticClass: "bg-c1"
  }, [_vm._v("1613")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("1614")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("1615")]), _c('td-primary', {
    staticClass: "empty"
  }), _c('td-primary', {
    staticClass: "empty"
  })], 1), _c('tr', [_c('th-secondary', [_vm._v("15층")]), _c('td-primary', {
    staticClass: "bg-e"
  }, [_vm._v("1501")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("1502")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("1503")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("1505")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("1506")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("1510")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("1514")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("1515")]), _c('td-primary', {
    staticClass: "empty"
  }), _c('td-primary', {
    staticClass: "empty"
  })], 1), _c('tr', [_c('th-secondary', [_vm._v("14층")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("1402")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("1403")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("1405")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("1408")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("1410")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("1411")]), _c('td-primary', {
    staticClass: "bg-c1"
  }, [_vm._v("1412")]), _c('td-primary', {
    staticClass: "bg-c1"
  }, [_vm._v("1413")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("1414")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("1415")]), _c('td-primary', {
    staticClass: "empty"
  }), _c('td-primary', {
    staticClass: "empty"
  })], 1), _c('tr', [_c('th-secondary', [_vm._v("13층")]), _c('td-primary', {
    staticClass: "bg-e"
  }, [_vm._v("1301")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("1302")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("1303")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("1304")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("1305")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("1306")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("1308")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("1309")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("1311")]), _c('td-primary', {
    staticClass: "bg-c1"
  }, [_vm._v("1312")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("1314")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("1315")]), _c('td-primary', {
    staticClass: "empty"
  }), _c('td-primary', {
    staticClass: "empty"
  })], 1), _c('tr', [_c('th-secondary', [_vm._v("12층")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("1203")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("1206")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("1207")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("1208")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("1209")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("1211")]), _c('td-primary', {
    staticClass: "bg-c1"
  }, [_vm._v("1212")]), _c('td-primary', {
    staticClass: "bg-c1"
  }, [_vm._v("1213")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("1214")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("1215")]), _c('td-primary', {
    staticClass: "empty"
  }), _c('td-primary', {
    staticClass: "empty"
  })], 1), _c('tr', [_c('th-secondary', [_vm._v("11층")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("1102")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("1104")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("1105")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("1106")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("1107")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("1109")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("1110")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("1111")]), _c('td-primary', {
    staticClass: "bg-c1"
  }, [_vm._v("1112")]), _c('td-primary', {
    staticClass: "bg-c1"
  }, [_vm._v("1113")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("1115")]), _c('td-primary', {
    staticClass: "empty"
  }), _c('td-primary', {
    staticClass: "empty"
  })], 1), _c('tr', [_c('th-secondary', [_vm._v("10층")]), _c('td-primary', {
    staticClass: "bg-e"
  }, [_vm._v("1001")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("1005")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("1007")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("1010")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("1011")]), _c('td-primary', {
    staticClass: "bg-c1"
  }, [_vm._v("1012")]), _c('td-primary', {
    staticClass: "bg-c1"
  }, [_vm._v("1013")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("1014")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("1015")]), _c('td-primary', {
    staticClass: "empty"
  }), _c('td-primary', {
    staticClass: "empty"
  })], 1), _c('tr', [_c('th-secondary', [_vm._v("9층")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("905")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("907")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("909")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("910")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("911")]), _c('td-primary', {
    staticClass: "bg-c1"
  }, [_vm._v("912")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("915")]), _c('td-primary', {
    staticClass: "empty"
  }), _c('td-primary', {
    staticClass: "empty"
  })], 1), _c('tr', [_c('th-secondary', [_vm._v("8층")]), _c('td-primary', {
    staticClass: "bg-e"
  }, [_vm._v("801")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("803")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("804")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("806")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("810")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("811")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-c1"
  }, [_vm._v("813")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("815")]), _c('td-primary', {
    staticClass: "empty"
  }), _c('td-primary', {
    staticClass: "empty"
  })], 1), _c('tr', [_c('th-secondary', [_vm._v("7층")]), _c('td-primary', {
    staticClass: "bg-e"
  }, [_vm._v("701")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("702")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("703")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("705")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("708")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("710")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-c1"
  }, [_vm._v("712")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("714")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("715")]), _c('td-primary', {
    staticClass: "empty"
  }), _c('td-primary', {
    staticClass: "empty"
  })], 1), _c('tr', [_c('th-secondary', [_vm._v("6층")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("603")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("605")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("607")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("609")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("610")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("611")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("614")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "empty"
  }), _c('td-primary', {
    staticClass: "empty"
  })], 1), _c('tr', [_c('th-secondary', [_vm._v("5층")]), _c('td-primary', {
    staticClass: "bg-e"
  }, [_vm._v("501")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("502")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("503")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("504")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("506")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("508")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("510")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("511")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("515")]), _c('td-primary', {
    staticClass: "empty"
  }), _c('td-primary', {
    staticClass: "empty"
  })], 1), _c('tr', [_c('th-secondary', [_vm._v("4층")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("402")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("404")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("405")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-g"
  }, [_vm._v("407")]), _c('td-primary', {
    staticClass: "bg-h"
  }, [_vm._v("408")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("411")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("412")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("416")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("417")])], 1), _c('tr', [_c('th-secondary', [_vm._v("3층")]), _c('td-primary', {
    staticClass: "bg-e"
  }, [_vm._v("301")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("302")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-a"
  }, [_vm._v("306")]), _c('td-primary', {
    staticClass: "bg-g"
  }, [_vm._v("307")]), _c('td-primary', {
    staticClass: "bg-h"
  }, [_vm._v("308")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("309")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', [_vm._v("-")]), _c('td-primary', {
    staticClass: "bg-d"
  }, [_vm._v("312")]), _c('td-primary', {
    staticClass: "bg-b"
  }, [_vm._v("313")]), _c('td-primary', {
    staticClass: "empty"
  }), _c('td-primary', {
    staticClass: "empty"
  }), _c('td-primary', {
    staticClass: "empty"
  }), _c('td-primary', {
    staticClass: "empty"
  })], 1)])])])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }