<template>
    <component :is="SkinComponent" v-model="form" v-bind="{ code, loading }" @save="save"></component>
</template>

<script>
import api from "@/api";
import { APPLICATION_TYPE, initApplication } from "@/assets/variables";
import cryptoAes from "@/plugins/crypto-aes";

export default {
    props: {
        skin: { type: String, default: null },
        code: { type: String, default: null },
    },

    data() {
        return {
            form: {},
            loading: false,
        };
    },

    computed: {
        SkinComponent() {
            return () => import(`./skin/${this.$props.skin}/input.vue`);
        },
    },

    mounted() {
        if(this.code === APPLICATION_TYPE.APPLICATION.value && !this.$store.state.ci) {
            alert("본인인증이 필요합니다.");
            this.$router.go(-1);
        }
    },

    methods: {
        async save(value) {
            if (this.loading) return;
            else this.loading = true;
            try {
                if(this.code === APPLICATION_TYPE.REGISTRATION.value) {
                    await api.v1.application.post({...value, code: this.code});
                    this.$router.push("/")
                }
                if(this.code === APPLICATION_TYPE.APPLICATION.value) {
                    this.form = { ...value };
                    let { ci, ...form } = this.form;
                    if(!this.form?._id) {
                        this.form = {
                            ...form,
                            code: this.code,
                            ci: cryptoAes.encrypt(ci),
                        }
                    }

                    let { post, put } = api.v1.application;
                    let { application } = await (this.form?._id ? put : post)(this.form);
                    // this.form = { ...value };
                    // let { files, ci, ...form } = this.form;

                    // if (!this.form?._id) {
                    //     this.form = {
                    //         ...form,
                    //         code: this.code,
                    //         ci: cryptoAes.encrypt(ci),
                    //     };
                    // } else {
                    //     delete this.form.files
                    // }

                    // let { post, put } = api.v1.application;
                    // let { application: { _id: _application, files: befFiles } = {} } = await (this.form?._id ? put : post)(this.form);

                    // let { file1, file2, file3, file4 } = files;
                    // let { file1: befFile1 = undefined, file2: befFile2  = undefined, file3: befFile3  = undefined, file4: befFile4  = undefined } = befFiles || {};

                    // file1 = await this.processFile({ file: file1, befFile: befFile1 }, _application, "file1");
                    // file2 = await this.processFile({ file: file2, befFile: befFile2 }, _application, "file2");
                    // file3 = await this.processFile({ file: file3, befFile: befFile3 }, _application, "file3");
                    // file4 = await this.processFile({ file: file4, befFile: befFile4 }, _application, "file4");

                    // await put({ _id: _application, files: { file1, file2, file3, file4 } });

                    this.$router.push(`/application/inquiry/${application?._id}`);
                }
                alert("저장되었습니다");
            } catch (error) {
                console.error(error);
            } finally {
                this.loading= false;
            }
        },

        // async processFile({ file, befFile = undefined }, _application, name) {
        //     if(befFile?.src && file?.src !== befFile?.src) {
        //         await api.v1.application.upload.delete({ _id: _application, name });
        //     }
        //     if (file && file instanceof File) {
        //         return (await api.v1.application.upload.post(_application, file, name))?.application?.files[name];
        //     }

        //     return file || null;
        // },

        handleError(error) {
            console.error(error);
            alert(error?.repsonse ? error?.response?.data?.message : error?.message);
        },
    },
};
</script>

<style scoped>
.v-btn__loader {
    color: white !important;
}
</style>