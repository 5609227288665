var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "사업안내",
            "tabActive": "오시는길",
            "bg": "/images/sub/business/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("사업안내")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "titWrapClass": "text-center"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("오시는길")];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "map-wrap"
  }, [_c('iframe', {
    staticClass: "map",
    attrs: {
      "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3160.375953108031!2d126.91908338962655!3d37.61684354912273!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9788e59f6843%3A0x966730b38c83629a!2z7ISc7Jq47Yq567OE7IucIOydgO2Pieq1rCDrtojqtJHrj5kgMzAyLTEz!5e0!3m2!1sko!2skr!4v1700026993936!5m2!1sko!2skr",
      "allowfullscreen": "",
      "loading": "lazy",
      "referrerpolicy": "no-referrer-when-downgrade"
    }
  })]), _c('v-card', {
    staticClass: "map-card",
    attrs: {
      "flat": "",
      "tile": "",
      "color": "grey"
    }
  }, [_c('div', {
    staticClass: "map-card__inner"
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('h3', [_vm._v("주소")])]), _c('v-col', [_vm._v("서울특별시 은평구 불광동 302-13번지 일원 (3호선, 6호선 연신내역 3번 출구)")])], 1), _c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('h3', [_vm._v("전화")])]), _c('v-col', [_vm._v("02-2602-6665")])], 1), _c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('h3', [_vm._v("이메일")])]), _c('v-col', [_vm._v("lumino816@naver.com")])], 1)], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }