<template>
    <u-sheet-outlined class="outlined-sheet--y"><slot /></u-sheet-outlined>
</template>

<script>
import USheetOutlined from "@/components/publish/styles/sheet/u-sheet-outlined.vue";

export default {
    props: {
    },
    components: {
        USheetOutlined,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>