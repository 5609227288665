var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "청약안내",
            "tabActive": "청약일정",
            "bg": "/images/sub/subscription/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("청약안내")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "titWrapClass": "text-center"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("청약일정")];
      },
      proxy: true
    }])
  }, [_c('v-row', {
    staticClass: "row--xl"
  }, [_c('v-col', {
    staticClass: "d-none d-lg-block",
    attrs: {
      "md": "4",
      "lg": "auto"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/subscription/schedule/schedule.jpg"
    }
  })], 1), _c('v-col', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "schedule-card",
    attrs: {
      "flat": "",
      "tile": "",
      "color": "secondary"
    }
  }, [_c('tit-tertiary', [_vm._v("루미노 816 청약 일정 안내")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "schedule-card",
    attrs: {
      "flat": "",
      "tile": "",
      "color": "grey lighten-5"
    }
  }, [_c('txt-quarternary', [_c('span', {
    staticClass: "grey--text text--darken-4 mr-10px font-weight-bold"
  }, [_vm._v("모집공고일")]), _vm._v("2024.09.23(월) ")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "schedule-card",
    attrs: {
      "flat": "",
      "tile": "",
      "color": "grey lighten-5"
    }
  }, [_c('txt-quarternary', [_c('span', {
    staticClass: "grey--text text--darken-4 mr-10px font-weight-bold"
  }, [_vm._v("청약신청")]), _vm._v("2024.10.03(목) ~ 10.06(일) ")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "schedule-card",
    attrs: {
      "flat": "",
      "tile": "",
      "color": "grey lighten-5"
    }
  }, [_c('txt-quarternary', [_c('span', {
    staticClass: "grey--text text--darken-4 mr-10px font-weight-bold"
  }, [_vm._v("당첨자 발표")]), _vm._v("2024.10.08(화) ")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "schedule-card schedule-card",
    attrs: {
      "flat": "",
      "tile": "",
      "color": "grey lighten-5"
    }
  }, [_c('txt-quarternary', [_c('span', {
    staticClass: "grey--text text--darken-4 mr-10px font-weight-bold"
  }, [_vm._v("서류(사본)제출")]), _vm._v("2024.10.09(수) ~ 10.14(월) ")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "schedule-card schedule-card",
    attrs: {
      "flat": "",
      "tile": "",
      "color": "grey lighten-5"
    }
  }, [_c('txt-quarternary', [_c('span', {
    staticClass: "grey--text text--darken-4 mr-10px font-weight-bold"
  }, [_vm._v("입주자사전점검")]), _vm._v("계약체결일(계약서 작성 전) ")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "schedule-card schedule-card__last",
    attrs: {
      "flat": "",
      "tile": "",
      "color": "grey lighten-5"
    }
  }, [_c('txt-quarternary', [_c('span', {
    staticClass: "grey--text text--darken-4 mr-10px font-weight-bold"
  }, [_vm._v("계약체결 및 서류(원본)제출")]), _vm._v("24.10.21(월) ~ 10.27(일) ")])], 1)], 1)], 1), _c('txt-primary', {
    staticClass: "mt-12px mt-md-16px"
  }, [_vm._v("※ 준공 및 사업 진행 현황에 따라 달라질 수 있습니다.")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }