var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.SkinComponent, _vm._b({
    tag: "component",
    on: {
      "save": _vm.save
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }, 'component', {
    code: _vm.code,
    loading: _vm.loading
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }