<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="사업안내" tabActive="오시는길" bg="/images/sub/business/visual.jpg">
                <template #title>사업안내</template>
            </sub-head-primary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first page-section--last">
            <template #tit>오시는길</template>

            <div class="map-wrap">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3160.375953108031!2d126.91908338962655!3d37.61684354912273!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9788e59f6843%3A0x966730b38c83629a!2z7ISc7Jq47Yq567OE7IucIOydgO2Pieq1rCDrtojqtJHrj5kgMzAyLTEz!5e0!3m2!1sko!2skr!4v1700026993936!5m2!1sko!2skr" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="map" />
            </div>
            <v-card flat tile color="grey" class="map-card">
                <div class="map-card__inner">
                    <v-row class="row--sm">
                        <v-col cols="12" md="2"><h3>주소</h3></v-col>
                        <v-col>서울특별시 은평구 불광동 302-13번지 일원 (3호선, 6호선 연신내역 3번 출구)</v-col>
                    </v-row>
                    <v-row class="row--sm">
                        <v-col cols="12" md="2"><h3>전화</h3></v-col>
                        <v-col>02-2602-6665</v-col>
                    </v-row>
                    <v-row class="row--sm">
                        <v-col cols="12" md="2"><h3>이메일</h3></v-col>
                        <v-col>lumino816@naver.com</v-col>
                    </v-row>
                </div>
            </v-card>
        </page-section-primary>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.map-wrap {
    position: relative;
    height: 300px;
    overflow: hidden;
}
.map {
    display: block;
    width: 100%;
    height: 406px;
    border: none;
    position: absolute;
    left: 0;
    bottom: 0;
}
.map-card {
    font-size: var(--txt-font-size-lg);
    color: #fff;
    padding: 24px 12px;
    width: 100%;
    max-width: calc(100% - 12px * 2);
    display: flex;
    align-items: center;
    margin: 20px auto 0;
    background: url("/images/sub/business/direction/map-card.png") no-repeat right;
    background-size: auto 100%;
    &__inner {
        width: 100%;
    }
}
@media (min-width: 768px) {
    .map-wrap {
        height: 400px;
    }
    .map {
        height: 506px;
    }
}
@media (min-width: 1024px) {
    .map-wrap {
        height: 586px;
    }
    .map {
        height: 692px;
    }
    .map-card {
        font-size: 18px;
        max-width: 996px;
        height: 194px;
        margin-top: 30px;
        padding: 0 24px;
    }
}
</style>
