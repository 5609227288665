<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="청약안내" tabActive="청약일정" bg="/images/sub/subscription/visual.jpg">
                <template #title>청약안내</template>
            </sub-head-primary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first page-section--last">
            <template #tit>청약일정</template>

            <v-row class="row--xl">
                <v-col md="4" lg="auto" class="d-none d-lg-block">
                    <v-img src="/images/sub/subscription/schedule/schedule.jpg"></v-img>
                </v-col>
                <v-col>
                    <v-row>
                        <v-col cols="12">
                            <v-card flat tile color="secondary" class="schedule-card">
                                <tit-tertiary>루미노 816 청약 일정 안내</tit-tertiary>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-card flat tile color="grey lighten-5" class="schedule-card">
                                <txt-quarternary>
                                    <span class="grey--text text--darken-4 mr-10px font-weight-bold">모집공고일</span>2024.09.23(월)
                                </txt-quarternary>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-card flat tile color="grey lighten-5" class="schedule-card">
                                <txt-quarternary>
                                    <span class="grey--text text--darken-4 mr-10px font-weight-bold">청약신청</span>2024.10.03(목) ~ 10.06(일)
                                </txt-quarternary>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-card flat tile color="grey lighten-5" class="schedule-card">
                                <txt-quarternary>
                                    <span class="grey--text text--darken-4 mr-10px font-weight-bold">당첨자 발표</span>2024.10.08(화)
                                </txt-quarternary>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-card flat tile color="grey lighten-5" class="schedule-card schedule-card">
                                <txt-quarternary>
                                    <span class="grey--text text--darken-4 mr-10px font-weight-bold">서류(사본)제출</span>2024.10.09(수) ~ 10.14(월)
                                </txt-quarternary>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-card flat tile color="grey lighten-5" class="schedule-card schedule-card">
                                <txt-quarternary>
                                    <span class="grey--text text--darken-4 mr-10px font-weight-bold">입주자사전점검</span>계약체결일(계약서 작성 전)
                                </txt-quarternary>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-card flat tile color="grey lighten-5" class="schedule-card schedule-card__last">
                                <txt-quarternary>
                                    <span class="grey--text text--darken-4 mr-10px font-weight-bold">계약체결 및 서류(원본)제출</span>24.10.21(월) ~ 10.27(일)
                                </txt-quarternary>
                            </v-card>
                        </v-col>
                    </v-row>
                    <txt-primary class="mt-12px mt-md-16px">※ 준공 및 사업 진행 현황에 따라 달라질 수 있습니다.</txt-primary>
                </v-col>
            </v-row>
        </page-section-primary>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import TitTertiary from "@/components/publish/parents/typography/tit-tertiary.vue";
import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";
import TxtQuarternary from "@/components/publish/parents/typography/txt-quarternary.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        TitTertiary,
        TxtPrimary,
        TxtQuarternary,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.schedule-card {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        width: 1px;
        height: 30px;
        background-color: var(--v-secondary-base);
        transform: translateY(100%);
    }
    &__last::before {
        display: none;
    }
}
@media (min-width: 768px) {
    .schedule-card {
        padding: 40px 0;
    }
}
</style>
