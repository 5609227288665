<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="청약안내" tabActive="임차인 자격요건" bg="/images/sub/subscription/visual.jpg">
                <template #title>청약안내</template>
            </sub-head-primary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first page-section--last">
            <template #tit>임차인 자격요건</template>

            <!-- S: 특별공급대상자 -->
            <div class="page-section__content">
                <u-tit-wrap-default class="tit-wrap--xs">
                    <tit-tertiary>특별공급대상자</tit-tertiary>
                </u-tit-wrap-default>
                <div class="list-item">
                    <txt-quarternary>
                        <v-row class="row--xxs">
                            <v-col cols="auto">
                                <span class="font-weight-bold grey--text text--darken-4">1)</span>
                            </v-col>
                            <v-col>
                                <span class="font-weight-bold grey--text text--darken-4">청년 계층 신청 자격</span> : 입주자모집공고일(2024. 09. 23.) 현재 아래의 요건을 모두 갖춘자(외국인제외)
                                <v-row class="row--xxs">
                                    <v-col cols="auto">※</v-col>
                                    <v-col>본 공고문의 모집 공고일은 2024. 09. 23입니다. 이는 청약자격의 판단기준일입니다.</v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </txt-quarternary>

                    <sheet-secondary class="my-16px my-md-20px">
                        <txt-quarternary>
                            <v-row class="row--xxs">
                                <v-col cols="auto">①</v-col>
                                <v-col>만 19세 이상 만 39세 이하인 자(1984년 09월 24일부터 2005년 09월 23일) 사이에 출생자</v-col>
                            </v-row>
                            <v-row class="row--xxs">
                                <v-col cols="auto">②</v-col>
                                <v-col>미혼</v-col>
                            </v-row>
                            <v-row class="row--xxs">
                                <v-col cols="auto">③</v-col>
                                <v-col>무주택자</v-col>
                            </v-row>
                            <v-row class="row--xxs">
                                <v-col cols="auto">④</v-col>
                                <v-col>
                                    소득 기준(*아래 전년도(2023년도) 도시근로자 가구원수별 가구당 월평균 소득 기준에 의함) <br />
                                    <v-row class="row--xxs">
                                        <v-col cols="auto">※</v-col>
                                        <v-col>해당 세대의 월평균 소득이 전년도 도시근로자 가구원(태아포함)수별 가구당 월평균소득의 120% 이하일 것</v-col>
                                    </v-row>
                                    <v-row class="row--xxs">
                                        <v-col cols="auto">※</v-col>
                                        <v-col>신청인이 소득이 있는 경우는 <span class="font-weight-bold">"해당 세대"</span> 월평균 소득</v-col>
                                    </v-row>
                                    <v-row class="row--xxs">
                                        <v-col cols="auto">※</v-col>
                                        <v-col>신청자가 단독 세대주인 경우 신청자 본인만의 소득, 세대원 또는 세대원이 있는 세대주인 경우 <span class="font-weight-bold">"해당 세대"</span> 모두의 소득</v-col>
                                    </v-row>
                                    <v-row class="row--xxs">
                                        <v-col cols="auto">※</v-col>
                                        <v-col>신청인이 소득이 없는 경우는 <span class="font-weight-bold">"사실증명(신고사실없음)"</span> 제출 후 신청자와 "부모"의 월평균 소득 합계(신청자 포함 3인 기준)</v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="row--xxs">
                                <v-col cols="auto">⑤</v-col>
                                <v-col>자동차(이륜차 포함) 무소유·미운행자 또는 2024년 기준 자동차가액 3,708만 원 이내의 자동차(이륜차 포함) 소유·운행자</v-col>
                            </v-row>
                            <v-row class="row--xxs">
                                <v-col cols="auto">⑥</v-col>
                                <v-col>2024년 기준 본인 자산 가액 2억7,300만원 이하</v-col>
                            </v-row>
                        </txt-quarternary>
                    </sheet-secondary>
                    <txt-quarternary>
                        <v-row class="row--xxs">
                            <v-col cols="auto">-</v-col>
                            <v-col>“해당 세대” 란 주민등록등본 상 직계존비속이며 형제자매의 소득은 제외합니다.</v-col>
                        </v-row>
                        <v-row class="row--xxs">
                            <v-col cols="auto">-</v-col>
                            <v-col>“무주택자＂란 주택을 소유하고 있지 않은 자를 말합니다.</v-col>
                        </v-row>
                        <v-row class="row--xxs">
                            <v-col cols="auto">※</v-col>
                            <v-col>자동차가액 기준은 공공주택 입주자 자산기준(공공주택 입주자 보유자산 관련 업무처리기준 (현행행정규칙)) 준용합니다. (단, 적용 가액은 자동차 소유 지분, 유자녀(출생일 기준), 장애인 및 국가유공자, 자동차 보유 대수, 저공해자동차(보조금 제외)에 따라 다르오니, 지원 전 공공주택 입주자 자산기준(공공주택 입주자 보유자산 관련 업무처리기준(현행행정규칙)) 반드시 확인하시기 바랍니다.)</v-col>
                        </v-row>
                        <v-row class="row--xxs">
                            <v-col cols="auto">※</v-col>
                            <v-col>
                                “자격 및 자산 자동차가액 판단”과 “등록(사용) 자동차가액 판단”의 차이
                                <v-row class="row--xxs">
                                    <v-col cols="auto">·</v-col>
                                    <v-col>자격 및 자산 자동차가액 판단 : 기준 가액 이하</v-col>
                                </v-row>
                                <v-row class="row--xxs">
                                    <v-col cols="auto">·</v-col>
                                    <v-col>등록(사용) 자동차가액 판단 : 기준 가액 이하만 등록하여 사용 가능 (단, 등록(사용) 할 자동차가액 판단 시 지분 소유는 미인정. 등록(사용) 할 자동차 전체 자동차가액으로만 판단.)</v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <div class="mt-16px mt-md-20px">
                            <v-row class="row--xxs">
                                <v-col cols="auto">*</v-col>
                                <v-col>전년도(2023년도) 도시근로자 가구원수별 가구당 월평균 소득 기준 (단위: 원)</v-col>
                            </v-row>
                            <div class="v-data-table v-data-table--border v-data-table--line my-10px">
                                <div class="v-data-table__wrapper">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th-primary>구분</th-primary>
                                                <th-primary>1인 가구</th-primary>
                                                <th-primary>2인 가구</th-primary>
                                                <th-primary>3인 가구</th-primary>
                                                <th-primary>4인 가구</th-primary>
                                                <th-primary>5인 가구</th-primary>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th-secondary>2023년도 100%</th-secondary>
                                                <td-primary>3,482,964</td-primary>
                                                <td-primary>5,415,712</td-primary>
                                                <td-primary>7,198,649</td-primary>
                                                <td-primary>8,248,467</td-primary>
                                                <td-primary>8,775,071</td-primary>
                                            </tr>
                                            <tr>
                                                <th-secondary>2023년도 110%</th-secondary>
                                                <td-primary>3,831,260</td-primary>
                                                <td-primary>5,957,283</td-primary>
                                                <td-primary>7,918,514</td-primary>
                                                <td-primary>9,073,314</td-primary>
                                                <td-primary>9,652,578</td-primary>
                                            </tr>
                                            <tr>
                                                <th-secondary>2023년도 120%</th-secondary>
                                                <td-primary>4,179,557</td-primary>
                                                <td-primary>6,498,854</td-primary>
                                                <td-primary>8,638,379</td-primary>
                                                <td-primary>9,898,160</td-primary>
                                                <td-primary>10,530,085</td-primary>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <v-row class="row--xxs">
                                <v-col cols="auto">-</v-col>
                                <v-col>6인 이상 가구는 5인가구 소득에 1인당 평균금액(788,211원)을 합산하여 산정</v-col>
                            </v-row>
                            <v-row class="row--xxs">
                                <v-col cols="auto">※</v-col>
                                <v-col>1인당 평균금액=(5인가구 월평균소득-3인가구 월평균소득) / 2</v-col>
                            </v-row>
                        </div>
                    </txt-quarternary>
                </div>
                <div class="list-item">
                    <txt-quarternary>
                        <v-row class="row--xxs">
                            <v-col cols="auto">
                                <span class="font-weight-bold grey--text text--darken-4">2)</span>
                            </v-col>
                            <v-col>
                                <span class="font-weight-bold grey--text text--darken-4">(예비)신혼부부 계층 신청자격</span> : 입주자모집공고일(2024. 09. 23) 현재 아래의 요건을 모두 갖춘자(외국인제외)
                                <v-row class="row--xxs">
                                    <v-col cols="auto">※</v-col>
                                    <v-col>본 공고문의 모집 공고일은 2024. 09. 23입니다. 이는 청약자격의 판단기준일입니다.</v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </txt-quarternary>
                    <sheet-secondary class="my-16px my-md-20px">
                        <txt-quarternary>
                            <v-row class="row--xxs">
                                <v-col cols="auto">①</v-col>
                                <v-col>만 19세 이상 만 39세 이하인 자(1984년 09월 24일부터 2005년 09월 23일 사이에 출생자, 신청자만 해당)</v-col>
                            </v-row>
                            <v-row class="row--xxs">
                                <v-col cols="auto">②</v-col>
                                <v-col>신혼부부는 혼인중인 자, 예비신혼부부는 해당 주택의 입주 전까지 혼인사실을 증명할 수 있는 자</v-col>
                            </v-row>
                            <v-row class="row--xxs">
                                <v-col cols="auto">③</v-col>
                                <v-col>신혼부부의 경우 <span class="font-weight-bold">“무주택세대구성원”</span>, 예비신혼부부의 경우 각각 무주택자</v-col>
                            </v-row>
                            <v-row class="row--xxs">
                                <v-col cols="auto">④</v-col>
                                <v-col>
                                    소득 기준(*아래 전년도(2023년도) 도시근로자 가구원수별 가구당 월평균 소득 기준에 의함)
                                    <v-row class="row--xxs">
                                        <v-col cols="auto">※</v-col>
                                        <v-col>세대구성원(예비 신혼부부의 경우 구성될) 모두의 월평균소득의 합계가 전년도 도시근로자 가구원(태아포함)수별 가구당 월평균소득의 120% 이하</v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="row--xxs">
                                <v-col cols="auto">⑤</v-col>
                                <v-col>자동차(이륜차 포함) 무소유·미운행자 또는 2024년 기준 자동차가액 3,708만 원 이내의 자동차(이륜차 포함) 소유·운행자</v-col>
                            </v-row>
                            <v-row class="row--xxs">
                                <v-col cols="auto">⑥</v-col>
                                <v-col>2024년 기준 세대(예비 신혼부부의 경우 구성될)의 총 자산가액 3억4,500만원 이하</v-col>
                            </v-row>
                        </txt-quarternary>
                    </sheet-secondary>
                    <txt-quarternary>
                        <v-row class="row--xxs">
                            <v-col cols="auto">-</v-col>
                            <v-col>신혼부부는 혼인 후 7년 이내(혼인 후 2,555일 까지, 2017.09.25. ~ 2024.09.23. 까지)의 부부를 말합니다.</v-col>
                        </v-row>
                        <v-row class="row--xxs">
                            <v-col cols="auto">-</v-col>
                            <v-col>신혼부부(예비신혼부부 포함)는 1세대로 간주하여 부부(예비부부) 중 1명이 대표로 1건만 신청이 가능하고, 두 명이 각각 중복 신청할 경우에는 전부 무효 처리됩니다.</v-col>
                        </v-row>
                        <v-row class="row--xxs">
                            <v-col cols="auto">-</v-col>
                            <v-col>신혼부부(예비신혼부부 포함)형은 청약을 신청한 당첨자 명의로 계약 체결이 가능하며, 부부공동명의 계약은 불가합니다.</v-col>
                        </v-row>
                        <v-row class="row--xxs">
                            <v-col cols="auto">-</v-col>
                            <v-col>
                                “무주택세대구성원”이란 전원이 주택을 소유하고 있지 않은 세대의 구성원을 말하며, 이때의 ‘세대’란 다음의 사람으로 구성된 집단(신청자가 세대별 주민등록표에 등재되어 있지 않은 경우는 제외)을 말합니다.
                                <v-row class="row--xxs">
                                    <v-col cols="auto">①</v-col>
                                    <v-col>신청자 본인</v-col>
                                </v-row>
                                <v-row class="row--xxs">
                                    <v-col cols="auto">②</v-col>
                                    <v-col>신청자의 배우자</v-col>
                                </v-row>
                                <v-row class="row--xxs">
                                    <v-col cols="auto">③</v-col>
                                    <v-col>신청자의 직계존속(신청자 배우자의 직계존속 포함)으로서 신청자 또는 신청자의 배우자와 동일세대 등재자</v-col>
                                </v-row>
                                <v-row class="row--xxs">
                                    <v-col cols="auto">④</v-col>
                                    <v-col>신청자의 직계비속(직계비속의 배우자 포함)으로서 신청자 또는 신청자 배우자와 동일 세대 등재자</v-col>
                                </v-row>
                                <v-row class="row--xxs">
                                    <v-col cols="auto">⑤</v-col>
                                    <v-col>신청자의 배우자의 직계비속으로서 신청자와 동일 세대 등재자</v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="row--xxs">
                            <v-col cols="auto">-</v-col>
                            <v-col>‘무주택자’란 주택을 소유하고 있지 않은 자를 말합니다.</v-col>
                        </v-row>
                        <v-row class="row--xxs">
                            <v-col cols="auto">※</v-col>
                            <v-col>자동차가액 기준은 공공주택 입주자 자산기준(공공주택 입주자 보유자산 관련 업무처리기준 (현행행정규칙)) 준용합니다. (단, 적용 가액은 자동차 소유 지분, 유자녀(출생일 기준), 장애인 및 국가유공자, 자동차 보유 대수, 저공해자동차(보조금 제외)에 따라 다르오니, 지원 전 공공주택 입주자 자산기준(공공주택 입주자 보유자산 관련 업무처리기준(현행행정규칙)) 반드시 확인하시기 바랍니다.)</v-col>
                        </v-row>
                        <v-row class="row--xxs">
                            <v-col cols="auto">※</v-col>
                            <v-col>
                                “자격 및 자산 자동차가액 판단”과 “등록(사용) 자동차가액 판단”의 차이
                                <v-row class="row--xxs">
                                    <v-col cols="auto">·</v-col>
                                    <v-col>자격 및 자산 자동차가액 판단 : 기준 가액 이하</v-col>
                                </v-row>
                                <v-row class="row--xxs">
                                    <v-col cols="auto">·</v-col>
                                    <v-col>등록(사용) 자동차가액 판단 : 기준 가액 이하만 등록하여 사용 가능 (단, 등록(사용) 할 자동차가액 판단 시 지분 소유는 미인정. 등록(사용) 할 자동차 전체 자동차가액으로만 판단.)</v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <div class="mt-16px mt-md-20px">
                            <v-row class="row--xxs">
                                <v-col cols="auto">*</v-col>
                                <v-col>전년도(2023년도) 도시근로자 가구원수별 가구당 월평균 소득 기준 (단위: 원)</v-col>
                            </v-row>
                            <div class="v-data-table v-data-table--border v-data-table--line my-10px">
                                <div class="v-data-table__wrapper">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th-primary>구분</th-primary>
                                                <th-primary>1인 가구</th-primary>
                                                <th-primary>2인 가구</th-primary>
                                                <th-primary>3인 가구</th-primary>
                                                <th-primary>4인 가구</th-primary>
                                                <th-primary>5인 가구</th-primary>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th-secondary>2023년도 100%</th-secondary>
                                                <td-primary>3,482,964</td-primary>
                                                <td-primary>5,415,712</td-primary>
                                                <td-primary>7,198,649</td-primary>
                                                <td-primary>8,248,467</td-primary>
                                                <td-primary>8,775,071</td-primary>
                                            </tr>
                                            <tr>
                                                <th-secondary>2023년도 110%</th-secondary>
                                                <td-primary>3,831,260</td-primary>
                                                <td-primary>5,957,283</td-primary>
                                                <td-primary>7,918,514</td-primary>
                                                <td-primary>9,073,314</td-primary>
                                                <td-primary>9,652,578</td-primary>
                                            </tr>
                                            <tr>
                                                <th-secondary>2023년도 120%</th-secondary>
                                                <td-primary>4,179,557</td-primary>
                                                <td-primary>6,498,854</td-primary>
                                                <td-primary>8,638,379</td-primary>
                                                <td-primary>9,898,160</td-primary>
                                                <td-primary>10,530,085</td-primary>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <v-row class="row--xxs">
                                <v-col cols="auto">-</v-col>
                                <v-col>6인 이상 가구는 5인가구 소득에 1인당 평균금액(788,211원)을 합산하여 산정</v-col>
                            </v-row>
                            <v-row class="row--xxs">
                                <v-col cols="auto">※</v-col>
                                <v-col>1인당 평균금액=(5인가구 월평균소득-3인가구 월평균소득) / 2</v-col>
                            </v-row>
                        </div>
                    </txt-quarternary>
                </div>
                <div class="list-item">
                    <txt-quarternary>
                        <v-row class="row--xxs">
                            <v-col cols="auto">
                                <span class="font-weight-bold grey--text text--darken-4">3)</span>
                            </v-col>
                            <v-col>
                                <span class="font-weight-bold grey--text text--darken-4">특별공급 선정 기준 및 방법</span>
                                <v-row class="row--xxs">
                                    <v-col cols="auto">※</v-col>
                                    <v-col>본 공고문의 모집 공고일은 2024. 09. 23.입니다. 이는 청약자격의 판단기준일입니다.</v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </txt-quarternary>

                    <sheet-secondary class="my-16px my-md-20px">
                        <txt-quarternary>
                            <v-row class="row--xxs">
                                <v-col cols="auto">①</v-col>
                                <v-col>
                                    소득 기준 순위
                                    <v-row class="row--xxs">
                                        <v-col cols="auto">- 1순위</v-col>
                                        <v-col>: 기준소득 100% 이하</v-col>
                                    </v-row>
                                    <v-row class="row--xxs">
                                        <v-col cols="auto">- 2순위</v-col>
                                        <v-col>: 기준소득 110% 이하</v-col>
                                    </v-row>
                                    <v-row class="row--xxs">
                                        <v-col cols="auto">- 3순위</v-col>
                                        <v-col>: 기준소득 120% 이하</v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="row--xxs">
                                <v-col cols="auto">②</v-col>
                                <v-col>
                                    지역 기준 순위 : 동일 소득일 경우 거주지역 우선 (대학 및 직장 소재지 포함)
                                    <v-row class="row--xxs">
                                        <v-col cols="auto">- 1순위</v-col>
                                        <v-col>: 해당 공급 대상 주택 소재지(서울특별시 은평구)</v-col>
                                    </v-row>
                                    <v-row class="row--xxs">
                                        <v-col cols="auto">- 2순위</v-col>
                                        <v-col>: 해당 공급 대상 주택 소재지 외(서울특별시)</v-col>
                                    </v-row>
                                    <v-row class="row--xxs">
                                        <v-col cols="auto">- 3순위</v-col>
                                        <v-col>: 그 외 지역</v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="row--xxs">
                                <v-col cols="auto">*</v-col>
                                <v-col>동일 순위 경쟁 시 “소득순위 → 지역순위 → 추첨” 순으로 선정 됩니다.</v-col>
                            </v-row>
                        </txt-quarternary>
                    </sheet-secondary>
                    <txt-quarternary>
                        <v-row class="row--xxs">
                            <v-col cols="auto">-</v-col>
                            <v-col>특별공급은 각각의 신청자격을 모두 충족하는 자를 대상으로 주택형(타입) 별로 접수하여, 상기 소득 및 해당 지역의 순위에 따라 당첨자를 선정하고 동일 순위 발생 시 공정한 방식에 의한 무작위 추첨으로 당첨자를 선정하며, 예비입주자 및 예비순번 역시 무작위 추첨으로 결정됩니다.</v-col>
                        </v-row>
                        <v-row class="row--xxs">
                            <v-col cols="auto">-</v-col>
                            <v-col>당첨자 선정 후 동·호수 결정(동·호수 변경은 불가) 또한 공정한 방식에 의한 무작위 추첨으로 당첨자를 선정합니다.</v-col>
                        </v-row>
                        <v-row class="row--xxs">
                            <v-col cols="auto">-</v-col>
                            <v-col>입주자격 조사결과에 따른 ‘부적격사유’에 대한 소명 의무는 전적으로 신청자에게 있습니다.</v-col>
                        </v-row>
                    </txt-quarternary>
                </div>
            </div>
            <!-- E: 특별공급대상자 -->

            <!-- S: 일반공급대상자 -->
            <div class="page-section__content">
                <u-tit-wrap-default class="tit-wrap--xs">
                    <v-row align="center" class="row--sm">
                        <v-col cols="auto"><tit-tertiary>일반공급대상자</tit-tertiary></v-col>
                        <v-col cols="auto">
                            <v-chip-group>
                                <chip-secondary color="primary">1인가구(청년, 대학생)</chip-secondary>
                            </v-chip-group>
                        </v-col>
                    </v-row>
                </u-tit-wrap-default>

                <div class="list-item">
                    <txt-quarternary>
                        <v-row class="row--xxs">
                            <v-col cols="auto">
                                <span class="font-weight-bold grey--text text--darken-4">1)</span>
                            </v-col>
                            <v-col>
                                <span class="font-weight-bold grey--text text--darken-4">청년 계층 신청자격</span> : 입주자모집공고일(2024. 09. 23) 현재 아래의 요건을 모두 갖춘 자(외국인제외)
                                <v-row class="row--xxs">
                                    <v-col cols="auto">※</v-col>
                                    <v-col>본 공고문의 모집 공고일은 2024. 09. 23.입니다. 이는 청약자격의 판단기준일입니다.</v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </txt-quarternary>
                    <sheet-secondary class="my-16px my-md-20px">
                        <txt-quarternary>
                            <v-row class="row--xxs">
                                <v-col cols="auto">①</v-col>
                                <v-col>만 19세 이상 만 39세 이하인 자 (1984년 09월 24일부터 2005년 09월 23일 사이에 출생자)</v-col>
                            </v-row>
                            <v-row class="row--xxs">
                                <v-col cols="auto">②</v-col>
                                <v-col>미혼</v-col>
                            </v-row>
                            <v-row class="row--xxs">
                                <v-col cols="auto">③</v-col>
                                <v-col>무주택자</v-col>
                            </v-row>
                            <v-row class="row--xxs">
                                <v-col cols="auto">④</v-col>
                                <v-col>소득 및 자산, 지역 요건 없음</v-col>
                            </v-row>
                            <v-row class="row--xxs">
                                <v-col cols="auto">⑤</v-col>
                                <v-col>자동차(이륜차 포함) 무소유·미운행자 또는 2024년 기준 자동차가액 3,708만 원 이내의 자동차(이륜차 포함) 소유·운행자</v-col>
                            </v-row>
                        </txt-quarternary>
                    </sheet-secondary>
                    <txt-quarternary>
                        <v-row class="row--xxs">
                            <v-col cols="auto">-</v-col>
                            <v-col>신청자 본인은 무주택자여야 하며, 세대 내 다른 구성원이 주택을 소유해도 신청 가능합니다.</v-col>
                        </v-row>
                        <v-row class="row--xxs">
                            <v-col cols="auto">-</v-col>
                            <v-col>‘무주택자’란 주택을 소유하고 있지 않은 자를 말합니다.</v-col>
                        </v-row>
                        <v-row class="row--xxs">
                            <v-col cols="auto">※</v-col>
                            <v-col>자동차가액 기준은 공공주택 입주자 자산기준(공공주택 입주자 보유자산 관련 업무처리기준 (현행행정규칙)) 준용합니다. (단, 적용 가액은 자동차 소유 지분, 유자녀(출생일 기준), 장애인 및 국가유공자, 자동차 보유 대수, 저공해자동차(보조금 제외)에 따라 다르오니, 지원 전 공공주택 입주자 자산기준(공공주택 입주자 보유자산 관련 업무처리기준(현행행정규칙)) 반드시 확인하시기 바랍니다.)</v-col>
                        </v-row>
                        <v-row class="row--xxs">
                            <v-col cols="auto">※</v-col>
                            <v-col>
                                “자격 및 자산 자동차가액 판단”과 “등록(사용) 자동차가액 판단”의 차이
                                <v-row class="row--xxs">
                                    <v-col cols="auto">·</v-col>
                                    <v-col>자격 및 자산 자동차가액 판단 : 기준 가액 이하</v-col>
                                </v-row>
                                <v-row class="row--xxs">
                                    <v-col cols="auto">·</v-col>
                                    <v-col>등록(사용) 자동차가액 판단 : 기준 가액 이하만 등록하여 사용 가능 (단, 등록(사용) 할 자동차가액 판단 시 지분 소유는 미인정. 등록(사용) 할 자동차 전체 자동차가액으로만 판단.)</v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </txt-quarternary>
                </div>
                <div class="list-item">
                    <txt-quarternary>
                        <v-row class="row--xxs">
                            <v-col cols="auto">
                                <span class="font-weight-bold grey--text text--darken-4">2)</span>
                            </v-col>
                            <v-col>
                                <span class="font-weight-bold grey--text text--darken-4">(예비)신혼부부 계층 신청자격</span> : 입주자모집공고일 2024. 09. 23. 현재 아래의 요건을 모두 갖춘 자(외국인제외)
                                <v-row class="row--xxs">
                                    <v-col cols="auto">※</v-col>
                                    <v-col>본 공고문의 모집 공고일은 2024. 09. 23.입니다. 이는 청약자격의 판단기준일입니다.</v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </txt-quarternary>
                    <sheet-secondary class="my-16px my-md-20px">
                        <txt-quarternary>
                            <v-row class="row--xxs">
                                <v-col cols="auto">①</v-col>
                                <v-col>만 19세 이상 만 39세 이하인 자 (1984년 09월 24일부터 2005년 09월 23일 사이에 출생자, 신청자만 해당)</v-col>
                            </v-row>
                            <v-row class="row--xxs">
                                <v-col cols="auto">②</v-col>
                                <v-col>신혼부부는 혼인중인 자, 예비신혼부부는 해당 주택의 입주 전까지 혼인사실을 증명할 수 있는 자</v-col>
                            </v-row>
                            <v-row class="row--xxs">
                                <v-col cols="auto">③</v-col>
                                <v-col>신혼부부의 경우 <span class="font-weight-bold">“무주택세대구성원”</span>, 예비신혼부부의 경우 각각 무주택자</v-col>
                            </v-row>
                            <v-row class="row--xxs">
                                <v-col cols="auto">④</v-col>
                                <v-col>소득 및 자산, 지역 요건 없음</v-col>
                            </v-row>
                            <v-row class="row--xxs">
                                <v-col cols="auto">⑤</v-col>
                                <v-col>자동차(이륜차 포함) 무소유·미운행자 또는 2024년 기준 자동차가액 3,708만 원 이내의 자동차(이륜차 포함) 소유·운행자</v-col>
                            </v-row>
                        </txt-quarternary>
                    </sheet-secondary>
                    <txt-quarternary>
                        <v-row class="row--xxs">
                            <v-col cols="auto">-</v-col>
                            <v-col>신혼부부는 혼인 후 7년이내(혼인 후 2,555일 까지, 2017.09.25. ~ 2024.09.23. 까지)의 부부를 말합니다.</v-col>
                        </v-row>
                        <v-row class="row--xxs">
                            <v-col cols="auto">-</v-col>
                            <v-col>신혼부부(예비신혼부부 포함)는 1세대로 간주하여 부부(예비부부) 중 1명이 대표로 1건만 신청이 가능하고, 두 명이 각각 중복 신청할 경우에는 전부 무효 처리됩니다.</v-col>
                        </v-row>
                        <v-row class="row--xxs">
                            <v-col cols="auto">-</v-col>
                            <v-col>신혼부부(예비신혼부부 포함)형은 청약을 신청한 당첨자 명의로 계약 체결이 가능하며, 부부공동명의 계약은 불가합니다.</v-col>
                        </v-row>
                        <v-row class="row--xxs">
                            <v-col cols="auto">-</v-col>
                            <v-col>
                                “무주택세대구성원”이란 전원이 주택을 소유하고 있지 않은 세대의 구성원을 말하며, 이때의 ‘세대’란 다음의 사람으로 구성된 집단(신청자가 세대별 주민등록표에 등재되어 있지 않은 경우는 제외)을 말합니다.
                                <v-row class="row--xxs">
                                    <v-col cols="auto">①</v-col>
                                    <v-col>신청자 본인</v-col>
                                </v-row>
                                <v-row class="row--xxs">
                                    <v-col cols="auto">②</v-col>
                                    <v-col>신청자의 배우자</v-col>
                                </v-row>
                                <v-row class="row--xxs">
                                    <v-col cols="auto">③</v-col>
                                    <v-col>신청자의 직계존속(신청자 배우자의 직계존속 포함)으로서 신청자 또는 신청자의 배우자와 동일 세대 등재자</v-col>
                                </v-row>
                                <v-row class="row--xxs">
                                    <v-col cols="auto">④</v-col>
                                    <v-col>신청자의 직계비속(직계비속의 배우자 포함)으로서 신청자 또는 신청자 배우자와 동일 세대 등재자</v-col>
                                </v-row>
                                <v-row class="row--xxs">
                                    <v-col cols="auto">⑤</v-col>
                                    <v-col>신청자의 배우자의 직계비속으로서 신청자와 동일 세대 등재자</v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="row--xxs">
                            <v-col cols="auto">-</v-col>
                            <v-col>‘무주택자’란 주택을 소유하고 있지 않은 자를 말합니다.</v-col>
                        </v-row>
                        <v-row class="row--xxs">
                            <v-col cols="auto">※</v-col>
                            <v-col>자동차가액 기준은 공공주택 입주자 자산기준(공공주택 입주자 보유자산 관련 업무처리기준 (현행행정규칙)) 준용합니다. (단, 적용 가액은 자동차 소유 지분, 유자녀(출생일 기준), 장애인 및 국가유공자, 자동차 보유 대수, 저공해자동차(보조금 제외)에 따라 다르오니, 지원 전 공공주택 입주자 자산기준(공공주택 입주자 보유자산 관련 업무처리기준(현행행정규칙)) 반드시 확인하시기 바랍니다.)</v-col>
                        </v-row>
                        <v-row class="row--xxs">
                            <v-col cols="auto">※</v-col>
                            <v-col>
                                “자격 및 자산 자동차가액 판단”과 “등록(사용) 자동차가액 판단”의 차이
                                <v-row class="row--xxs">
                                    <v-col cols="auto">·</v-col>
                                    <v-col>자격 및 자산 자동차가액 판단 : 기준 가액 이하</v-col>
                                </v-row>
                                <v-row class="row--xxs">
                                    <v-col cols="auto">·</v-col>
                                    <v-col>등록(사용) 자동차가액 판단 : 기준 가액 이하만 등록하여 사용 가능 (단, 등록(사용) 할 자동차가액 판단 시 지분 소유는 미인정. 등록(사용) 할 자동차 전체 자동차가액으로만 판단.)</v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </txt-quarternary>
                </div>
                <div class="list-item">
                    <txt-quarternary>
                        <v-row class="row--xxs">
                            <v-col cols="auto">
                                <span class="font-weight-bold grey--text text--darken-4">3)</span>
                            </v-col>
                            <v-col>
                                <span class="font-weight-bold grey--text text--darken-4">일반공급 선정기준 및 방법</span>
                                <v-row class="row--xxs">
                                    <v-col cols="auto">※</v-col>
                                    <v-col>
                                        본 공고문의 모집 공고일은 2024. 09. 23. 입니다. 이는 청약자격의 판단기준일입니다.
                                        <v-row class="row--xxs">
                                            <v-col cols="auto">-</v-col>
                                            <v-col>일반공급은 각각의 신청자격을 모두 충족하는 자를 대상으로 주택형(타입) 별로 접수하여, 공정한 방식에 의한 무작위 추첨으로 당첨자를 선정하며, 예비입주자 및 예비순번 역시 무작위 추첨으로 결정됩니다.</v-col>
                                        </v-row>
                                        <v-row class="row--xxs">
                                            <v-col cols="auto">-</v-col>
                                            <v-col>당첨자 선정 후 동·호수 결정(동·호수 변경은 불가) 또한 공정한 방식에 의한 무작위 추첨으로 당첨자를 선정합니다.</v-col>
                                        </v-row>
                                        <v-row class="row--xxs">
                                            <v-col cols="auto">-</v-col>
                                            <v-col>입주자격 조사결과에 따른 ‘부적격사유’에 대한 소명 의무는 전적으로 신청자에게 있습니다.</v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </txt-quarternary>
                </div>
            </div>
            <!-- E: 일반공급대상자 -->
        </page-section-primary>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import SheetPrimary from "@/components/publish/parents/sheets/sheet-primary.vue";
import SheetSecondary from "@/components/publish/parents/sheets/sheet-secondary.vue";
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import TitTertiary from "@/components/publish/parents/typography/tit-tertiary.vue";
import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";
import TxtQuarternary from "@/components/publish/parents/typography/txt-quarternary.vue";
import ChipPrimary from "@/components/publish/parents/chips/chip-primary.vue";
import ChipSecondary from "@/components/publish/parents/chips/chip-secondary.vue";
import TablePrimary from "@/components/publish/parents/tables/table-primary.vue";
import ThPrimary from "@/components/publish/parents/tables/th-primary.vue";
import ThSecondary from "@/components/publish/parents/tables/th-secondary.vue";
import TdPrimary from "@/components/publish/parents/tables/td-primary.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        SheetPrimary,
        SheetSecondary,
        UTitWrapDefault,
        TitTertiary,
        TxtPrimary,
        TxtQuarternary,
        ChipPrimary,
        ChipSecondary,
        TablePrimary,
        ThPrimary,
        ThSecondary,
        TdPrimary,
    },
    data() {
        return {
            headers: [
                { text: "구분", value: "Title1" },
                { text: "Title2", value: "Title2" },
                { text: "Title3", value: "Title3" },
            ],
            items: [
                { Title1: "Text1-1", Title2: "Text1-2", Title3: "Text1-3" },
                { Title1: "Text2-1", Title2: "Text2-2", Title3: "Text2-3" },
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.chip-wrap {
    margin-top: 30px;
    margin-bottom: 14px;
    width: 160px;
}
.list-item + .list-item {
    margin-top: 28px;
}
@media (min-width: 768px) {
    .chip-wrap {
        margin-top: 40px;
        margin-bottom: 16px;
        width: 206px;
    }
    .list-item + .list-item {
        margin-top: 36px;
    }
}
</style>
