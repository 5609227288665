<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="청약안내" tabActive="제출서류" bg="/images/sub/subscription/visual.jpg">
                <template #title>청약안내</template>
            </sub-head-primary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first page-section--last">
            <template #tit>LUMINO 816 청약신청 제출서류</template>

            <v-row>
                <template v-for="(item, index) in items">
                    <v-col cols="6" md="4" :key="index">
                        <v-img :src="item.image" max-width="384" class="document">
                            <div class="document__caption">
                                {{ item.name }}
                            </div>
                        </v-img>
                    </v-col>
                </template>
            </v-row>
            <div class="btn-wrap btn-wrap--lg">
                <btn-primary x-large href="/assets/document/documents.zip" download="LUMINO 816 청약신청 제출서류.zip">
                    제출서류 다운로드
                    <v-img class="ml-16px ml-lg-24px" src="/images/icon/icon-download-white.png" />
                </btn-primary>
            </div>
        </page-section-primary>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import btnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        btnPrimary,
    },
    data() {
        return {
            items: [
                {
                    name: "0. 임대보증금 비율 확약서",
                    image: "/images/sub/subscription/document/document-0.jpg",
                },
                {
                    name: "1. 서류 체크리스트 [일반공급]",
                    image: "/images/sub/subscription/document/document-1-1.jpg",
                },
                {
                    name: "1. 서류 체크리스트 [특별공급]",
                    image: "/images/sub/subscription/document/document-1-2.jpg",
                },
                {
                    name: "2. 예비신혼부부 세대구성 확인서",
                    image: "/images/sub/subscription/document/document-2.jpg",
                },
                {
                    name: "3. 공공지원민간임대주택 등 공급신청서",
                    image: "/images/sub/subscription/document/document-3.jpg",
                },
                {
                    name: "4. 서약서",
                    image: "/images/sub/subscription/document/document-4.jpg",
                },
                {
                    name: "5. 월평균 소득현황",
                    image: "/images/sub/subscription/document/document-5.jpg",
                },
                {
                    name: "6. 무소득 확인서",
                    image: "/images/sub/subscription/document/document-6.jpg",
                },
                {
                    name: "7. 개인정보 활용 동의서",
                    image: "/images/sub/subscription/document/document-7.jpg",
                },
                {
                    name: "8. 자산 보유 사실 확인서",
                    image: "/images/sub/subscription/document/document-8.jpg",
                },
                {
                    name: "9. 금융정보등(금융,신용,보험정보)제공 동의서",
                    image: "/images/sub/subscription/document/document-9.jpg",
                },
                {
                    name: "10. 비사업자 확인각서",
                    image: "/images/sub/subscription/document/document-10.jpg",
                },
                {
                    name: "11. 주차등록 기준 심사 요청서",
                    image: "/images/sub/subscription/document/document-11.jpg",
                },
                {
                    name: "12. 위임장",
                    image: "/images/sub/subscription/document/document-12.jpg",
                },
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.document {
    position: relative;
    &__caption {
        position: absolute;
        bottom: 0;
        width: 100%;
        min-height: 56px;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: var(--txt-font-size-lg);
        color: #fff;
        transition: all 0.5s ease;
        padding: 12px;
    }
    &:hover .document__caption {
        min-height: 100%;
    }
}
</style>
