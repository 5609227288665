<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="단지안내" tabActive="단위세대 평면안내" bg="/images/sub/complex/visual.jpg">
                <template #title>단지안내</template>
            </sub-head-primary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first page-section--last">
            <template #tit>단위세대 평면안내</template>

            <!-- S: 탭 -->
            <div class="page-section__content">
                <v-row class="row--sm">
                    <v-col cols="3">
                        <u-btn-flat-pill v-if="$route.hash == '#a' || $route.hash == ''" color="tertiary" class="w-100 v-size--xx-large">
                            <u-txt-default class="txt--lg white--text">A TYPE</u-txt-default>
                        </u-btn-flat-pill>
                        <u-btn-flat-pill to="#a" v-else color="grey lighten-5" class="w-100 v-size--xx-large" @click="filter.category = 'a'">
                            <u-txt-default class="txt--lg grey--text">A TYPE</u-txt-default>
                        </u-btn-flat-pill>
                    </v-col>
                    <v-col cols="3">
                        <u-btn-flat-pill v-if="$route.hash == '#b'" color="tertiary" class="w-100 v-size--xx-large">
                            <u-txt-default class="txt--lg white--text">B TYPE</u-txt-default>
                        </u-btn-flat-pill>
                        <u-btn-flat-pill to="#b" v-else color="grey lighten-5" class="w-100 v-size--xx-large" @click="filter.category = 'b'">
                            <u-txt-default class="txt--lg grey--text">B TYPE</u-txt-default>
                        </u-btn-flat-pill>
                    </v-col>
                    <v-col cols="3">
                        <u-btn-flat-pill v-if="$route.hash == '#c'" color="tertiary" class="w-100 v-size--xx-large">
                            <u-txt-default class="txt--lg white--text">C TYPE</u-txt-default>
                        </u-btn-flat-pill>
                        <u-btn-flat-pill to="#c" v-else color="grey lighten-5" class="w-100 v-size--xx-large" @click="filter.category = 'c'">
                            <u-txt-default class="txt--lg grey--text">C TYPE</u-txt-default>
                        </u-btn-flat-pill>
                    </v-col>
                    <v-col cols="3">
                        <u-btn-flat-pill v-if="$route.hash == '#d'" color="tertiary" class="w-100 v-size--xx-large">
                            <u-txt-default class="txt--lg white--text">D TYPE</u-txt-default>
                        </u-btn-flat-pill>
                        <u-btn-flat-pill to="#d" v-else color="grey lighten-5" class="w-100 v-size--xx-large" @click="filter.category = 'd'">
                            <u-txt-default class="txt--lg grey--text">D TYPE</u-txt-default>
                        </u-btn-flat-pill>
                    </v-col>
                    <v-col cols="3">
                        <u-btn-flat-pill v-if="$route.hash == '#e'" color="tertiary" class="w-100 v-size--xx-large">
                            <u-txt-default class="txt--lg white--text">E TYPE</u-txt-default>
                        </u-btn-flat-pill>
                        <u-btn-flat-pill to="#e" v-else color="grey lighten-5" class="w-100 v-size--xx-large" @click="filter.category = 'e'">
                            <u-txt-default class="txt--lg grey--text">E TYPE</u-txt-default>
                        </u-btn-flat-pill>
                    </v-col>
                    <v-col cols="3">
                        <u-btn-flat-pill v-if="$route.hash == '#g'" color="tertiary" class="w-100 v-size--xx-large">
                            <u-txt-default class="txt--lg white--text">G TYPE</u-txt-default>
                        </u-btn-flat-pill>
                        <u-btn-flat-pill to="#g" v-else color="grey lighten-5" class="w-100 v-size--xx-large" @click="filter.category = 'g'">
                            <u-txt-default class="txt--lg grey--text">G TYPE</u-txt-default>
                        </u-btn-flat-pill>
                    </v-col>
                    <v-col cols="3">
                        <u-btn-flat-pill v-if="$route.hash == '#h'" color="tertiary" class="w-100 v-size--xx-large">
                            <u-txt-default class="txt--lg white--text">H TYPE</u-txt-default>
                        </u-btn-flat-pill>
                        <u-btn-flat-pill to="#h" v-else color="grey lighten-5" class="w-100 v-size--xx-large" @click="filter.category = 'h'">
                            <u-txt-default class="txt--lg grey--text">H TYPE</u-txt-default>
                        </u-btn-flat-pill>
                    </v-col>
                </v-row>
            </div>
            <!-- E: 탭 -->

            <!-- S: 표 -->
            <div class="page-section__content">
                <unit-table />
            </div>
            <!-- E: 표 -->

            <!-- S: 이미지 -->
            <div class="page-section__content">
                <sheet-secondary class="py-md-40px" align="center">
                    <div class="txt text-center mb-12px">동 · 호수에 따라 구조가 좌우대칭되어 적용될 수 있습니다</div>
                    <img v-if="$route.hash == '#a' || $route.hash == ''" src="/images/sub/complex/unit/a-type.jpg" alt="" />
                    <img v-if="$route.hash == '#b'" src="/images/sub/complex/unit/b-type.jpg" alt="" />
                    <img v-if="$route.hash == '#c'" src="/images/sub/complex/unit/c1-type.jpg" alt="" />
                    <img v-if="$route.hash == '#d'" src="/images/sub/complex/unit/d-type.jpg" alt="" />
                    <img v-if="$route.hash == '#e'" src="/images/sub/complex/unit/e-type.jpg" alt="" />
                    <img v-if="$route.hash == '#g'" src="/images/sub/complex/unit/g-type.jpg" alt="" />
                    <img v-if="$route.hash == '#h'" src="/images/sub/complex/unit/h-type.jpg" alt="" />
                </sheet-secondary>
            </div>
            <!-- E: 이미지 -->
        </page-section-primary>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import UBtnFlatPill from "@/components/publish/styles/buttons/u-btn-flat-pill.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UnitTable from "@/components/client/sub/unit-table.vue";
import SheetSecondary from "@/components/publish/parents/sheets/sheet-secondary.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        UBtnFlatPill,
        UTxtDefault,
        UnitTable,
        SheetSecondary,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
