var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "청약안내",
            "tabActive": "임차인 자격요건",
            "bg": "/images/sub/subscription/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("청약안내")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "titWrapClass": "text-center"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("임차인 자격요건")];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "page-section__content"
  }, [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--xs"
  }, [_c('tit-tertiary', [_vm._v("특별공급대상자")])], 1), _c('div', {
    staticClass: "list-item"
  }, [_c('txt-quarternary', [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "font-weight-bold grey--text text--darken-4"
  }, [_vm._v("1)")])]), _c('v-col', [_c('span', {
    staticClass: "font-weight-bold grey--text text--darken-4"
  }, [_vm._v("청년 계층 신청 자격")]), _vm._v(" : 입주자모집공고일(2024. 09. 23.) 현재 아래의 요건을 모두 갖춘자(외국인제외) "), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("※")]), _c('v-col', [_vm._v("본 공고문의 모집 공고일은 2024. 09. 23입니다. 이는 청약자격의 판단기준일입니다.")])], 1)], 1)], 1)], 1), _c('sheet-secondary', {
    staticClass: "my-16px my-md-20px"
  }, [_c('txt-quarternary', [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("①")]), _c('v-col', [_vm._v("만 19세 이상 만 39세 이하인 자(1984년 09월 24일부터 2005년 09월 23일) 사이에 출생자")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("②")]), _c('v-col', [_vm._v("미혼")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("③")]), _c('v-col', [_vm._v("무주택자")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("④")]), _c('v-col', [_vm._v(" 소득 기준(*아래 전년도(2023년도) 도시근로자 가구원수별 가구당 월평균 소득 기준에 의함) "), _c('br'), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("※")]), _c('v-col', [_vm._v("해당 세대의 월평균 소득이 전년도 도시근로자 가구원(태아포함)수별 가구당 월평균소득의 120% 이하일 것")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("※")]), _c('v-col', [_vm._v("신청인이 소득이 있는 경우는 "), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("\"해당 세대\"")]), _vm._v(" 월평균 소득")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("※")]), _c('v-col', [_vm._v("신청자가 단독 세대주인 경우 신청자 본인만의 소득, 세대원 또는 세대원이 있는 세대주인 경우 "), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("\"해당 세대\"")]), _vm._v(" 모두의 소득")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("※")]), _c('v-col', [_vm._v("신청인이 소득이 없는 경우는 "), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("\"사실증명(신고사실없음)\"")]), _vm._v(" 제출 후 신청자와 \"부모\"의 월평균 소득 합계(신청자 포함 3인 기준)")])], 1)], 1)], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("⑤")]), _c('v-col', [_vm._v("자동차(이륜차 포함) 무소유·미운행자 또는 2024년 기준 자동차가액 3,708만 원 이내의 자동차(이륜차 포함) 소유·운행자")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("⑥")]), _c('v-col', [_vm._v("2024년 기준 본인 자산 가액 2억7,300만원 이하")])], 1)], 1)], 1), _c('txt-quarternary', [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_vm._v("“해당 세대” 란 주민등록등본 상 직계존비속이며 형제자매의 소득은 제외합니다.")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_vm._v("“무주택자＂란 주택을 소유하고 있지 않은 자를 말합니다.")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("※")]), _c('v-col', [_vm._v("자동차가액 기준은 공공주택 입주자 자산기준(공공주택 입주자 보유자산 관련 업무처리기준 (현행행정규칙)) 준용합니다. (단, 적용 가액은 자동차 소유 지분, 유자녀(출생일 기준), 장애인 및 국가유공자, 자동차 보유 대수, 저공해자동차(보조금 제외)에 따라 다르오니, 지원 전 공공주택 입주자 자산기준(공공주택 입주자 보유자산 관련 업무처리기준(현행행정규칙)) 반드시 확인하시기 바랍니다.)")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("※")]), _c('v-col', [_vm._v(" “자격 및 자산 자동차가액 판단”과 “등록(사용) 자동차가액 판단”의 차이 "), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("자격 및 자산 자동차가액 판단 : 기준 가액 이하")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("등록(사용) 자동차가액 판단 : 기준 가액 이하만 등록하여 사용 가능 (단, 등록(사용) 할 자동차가액 판단 시 지분 소유는 미인정. 등록(사용) 할 자동차 전체 자동차가액으로만 판단.)")])], 1)], 1)], 1), _c('div', {
    staticClass: "mt-16px mt-md-20px"
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("*")]), _c('v-col', [_vm._v("전년도(2023년도) 도시근로자 가구원수별 가구당 월평균 소득 기준 (단위: 원)")])], 1), _c('div', {
    staticClass: "v-data-table v-data-table--border v-data-table--line my-10px"
  }, [_c('div', {
    staticClass: "v-data-table__wrapper"
  }, [_c('table', [_c('thead', [_c('tr', [_c('th-primary', [_vm._v("구분")]), _c('th-primary', [_vm._v("1인 가구")]), _c('th-primary', [_vm._v("2인 가구")]), _c('th-primary', [_vm._v("3인 가구")]), _c('th-primary', [_vm._v("4인 가구")]), _c('th-primary', [_vm._v("5인 가구")])], 1)]), _c('tbody', [_c('tr', [_c('th-secondary', [_vm._v("2023년도 100%")]), _c('td-primary', [_vm._v("3,482,964")]), _c('td-primary', [_vm._v("5,415,712")]), _c('td-primary', [_vm._v("7,198,649")]), _c('td-primary', [_vm._v("8,248,467")]), _c('td-primary', [_vm._v("8,775,071")])], 1), _c('tr', [_c('th-secondary', [_vm._v("2023년도 110%")]), _c('td-primary', [_vm._v("3,831,260")]), _c('td-primary', [_vm._v("5,957,283")]), _c('td-primary', [_vm._v("7,918,514")]), _c('td-primary', [_vm._v("9,073,314")]), _c('td-primary', [_vm._v("9,652,578")])], 1), _c('tr', [_c('th-secondary', [_vm._v("2023년도 120%")]), _c('td-primary', [_vm._v("4,179,557")]), _c('td-primary', [_vm._v("6,498,854")]), _c('td-primary', [_vm._v("8,638,379")]), _c('td-primary', [_vm._v("9,898,160")]), _c('td-primary', [_vm._v("10,530,085")])], 1)])])])]), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_vm._v("6인 이상 가구는 5인가구 소득에 1인당 평균금액(788,211원)을 합산하여 산정")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("※")]), _c('v-col', [_vm._v("1인당 평균금액=(5인가구 월평균소득-3인가구 월평균소득) / 2")])], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "list-item"
  }, [_c('txt-quarternary', [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "font-weight-bold grey--text text--darken-4"
  }, [_vm._v("2)")])]), _c('v-col', [_c('span', {
    staticClass: "font-weight-bold grey--text text--darken-4"
  }, [_vm._v("(예비)신혼부부 계층 신청자격")]), _vm._v(" : 입주자모집공고일(2024. 09. 23) 현재 아래의 요건을 모두 갖춘자(외국인제외) "), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("※")]), _c('v-col', [_vm._v("본 공고문의 모집 공고일은 2024. 09. 23입니다. 이는 청약자격의 판단기준일입니다.")])], 1)], 1)], 1)], 1), _c('sheet-secondary', {
    staticClass: "my-16px my-md-20px"
  }, [_c('txt-quarternary', [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("①")]), _c('v-col', [_vm._v("만 19세 이상 만 39세 이하인 자(1984년 09월 24일부터 2005년 09월 23일 사이에 출생자, 신청자만 해당)")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("②")]), _c('v-col', [_vm._v("신혼부부는 혼인중인 자, 예비신혼부부는 해당 주택의 입주 전까지 혼인사실을 증명할 수 있는 자")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("③")]), _c('v-col', [_vm._v("신혼부부의 경우 "), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("“무주택세대구성원”")]), _vm._v(", 예비신혼부부의 경우 각각 무주택자")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("④")]), _c('v-col', [_vm._v(" 소득 기준(*아래 전년도(2023년도) 도시근로자 가구원수별 가구당 월평균 소득 기준에 의함) "), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("※")]), _c('v-col', [_vm._v("세대구성원(예비 신혼부부의 경우 구성될) 모두의 월평균소득의 합계가 전년도 도시근로자 가구원(태아포함)수별 가구당 월평균소득의 120% 이하")])], 1)], 1)], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("⑤")]), _c('v-col', [_vm._v("자동차(이륜차 포함) 무소유·미운행자 또는 2024년 기준 자동차가액 3,708만 원 이내의 자동차(이륜차 포함) 소유·운행자")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("⑥")]), _c('v-col', [_vm._v("2024년 기준 세대(예비 신혼부부의 경우 구성될)의 총 자산가액 3억4,500만원 이하")])], 1)], 1)], 1), _c('txt-quarternary', [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_vm._v("신혼부부는 혼인 후 7년 이내(혼인 후 2,555일 까지, 2017.09.25. ~ 2024.09.23. 까지)의 부부를 말합니다.")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_vm._v("신혼부부(예비신혼부부 포함)는 1세대로 간주하여 부부(예비부부) 중 1명이 대표로 1건만 신청이 가능하고, 두 명이 각각 중복 신청할 경우에는 전부 무효 처리됩니다.")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_vm._v("신혼부부(예비신혼부부 포함)형은 청약을 신청한 당첨자 명의로 계약 체결이 가능하며, 부부공동명의 계약은 불가합니다.")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_vm._v(" “무주택세대구성원”이란 전원이 주택을 소유하고 있지 않은 세대의 구성원을 말하며, 이때의 ‘세대’란 다음의 사람으로 구성된 집단(신청자가 세대별 주민등록표에 등재되어 있지 않은 경우는 제외)을 말합니다. "), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("①")]), _c('v-col', [_vm._v("신청자 본인")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("②")]), _c('v-col', [_vm._v("신청자의 배우자")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("③")]), _c('v-col', [_vm._v("신청자의 직계존속(신청자 배우자의 직계존속 포함)으로서 신청자 또는 신청자의 배우자와 동일세대 등재자")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("④")]), _c('v-col', [_vm._v("신청자의 직계비속(직계비속의 배우자 포함)으로서 신청자 또는 신청자 배우자와 동일 세대 등재자")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("⑤")]), _c('v-col', [_vm._v("신청자의 배우자의 직계비속으로서 신청자와 동일 세대 등재자")])], 1)], 1)], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_vm._v("‘무주택자’란 주택을 소유하고 있지 않은 자를 말합니다.")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("※")]), _c('v-col', [_vm._v("자동차가액 기준은 공공주택 입주자 자산기준(공공주택 입주자 보유자산 관련 업무처리기준 (현행행정규칙)) 준용합니다. (단, 적용 가액은 자동차 소유 지분, 유자녀(출생일 기준), 장애인 및 국가유공자, 자동차 보유 대수, 저공해자동차(보조금 제외)에 따라 다르오니, 지원 전 공공주택 입주자 자산기준(공공주택 입주자 보유자산 관련 업무처리기준(현행행정규칙)) 반드시 확인하시기 바랍니다.)")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("※")]), _c('v-col', [_vm._v(" “자격 및 자산 자동차가액 판단”과 “등록(사용) 자동차가액 판단”의 차이 "), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("자격 및 자산 자동차가액 판단 : 기준 가액 이하")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("등록(사용) 자동차가액 판단 : 기준 가액 이하만 등록하여 사용 가능 (단, 등록(사용) 할 자동차가액 판단 시 지분 소유는 미인정. 등록(사용) 할 자동차 전체 자동차가액으로만 판단.)")])], 1)], 1)], 1), _c('div', {
    staticClass: "mt-16px mt-md-20px"
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("*")]), _c('v-col', [_vm._v("전년도(2023년도) 도시근로자 가구원수별 가구당 월평균 소득 기준 (단위: 원)")])], 1), _c('div', {
    staticClass: "v-data-table v-data-table--border v-data-table--line my-10px"
  }, [_c('div', {
    staticClass: "v-data-table__wrapper"
  }, [_c('table', [_c('thead', [_c('tr', [_c('th-primary', [_vm._v("구분")]), _c('th-primary', [_vm._v("1인 가구")]), _c('th-primary', [_vm._v("2인 가구")]), _c('th-primary', [_vm._v("3인 가구")]), _c('th-primary', [_vm._v("4인 가구")]), _c('th-primary', [_vm._v("5인 가구")])], 1)]), _c('tbody', [_c('tr', [_c('th-secondary', [_vm._v("2023년도 100%")]), _c('td-primary', [_vm._v("3,482,964")]), _c('td-primary', [_vm._v("5,415,712")]), _c('td-primary', [_vm._v("7,198,649")]), _c('td-primary', [_vm._v("8,248,467")]), _c('td-primary', [_vm._v("8,775,071")])], 1), _c('tr', [_c('th-secondary', [_vm._v("2023년도 110%")]), _c('td-primary', [_vm._v("3,831,260")]), _c('td-primary', [_vm._v("5,957,283")]), _c('td-primary', [_vm._v("7,918,514")]), _c('td-primary', [_vm._v("9,073,314")]), _c('td-primary', [_vm._v("9,652,578")])], 1), _c('tr', [_c('th-secondary', [_vm._v("2023년도 120%")]), _c('td-primary', [_vm._v("4,179,557")]), _c('td-primary', [_vm._v("6,498,854")]), _c('td-primary', [_vm._v("8,638,379")]), _c('td-primary', [_vm._v("9,898,160")]), _c('td-primary', [_vm._v("10,530,085")])], 1)])])])]), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_vm._v("6인 이상 가구는 5인가구 소득에 1인당 평균금액(788,211원)을 합산하여 산정")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("※")]), _c('v-col', [_vm._v("1인당 평균금액=(5인가구 월평균소득-3인가구 월평균소득) / 2")])], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "list-item"
  }, [_c('txt-quarternary', [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "font-weight-bold grey--text text--darken-4"
  }, [_vm._v("3)")])]), _c('v-col', [_c('span', {
    staticClass: "font-weight-bold grey--text text--darken-4"
  }, [_vm._v("특별공급 선정 기준 및 방법")]), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("※")]), _c('v-col', [_vm._v("본 공고문의 모집 공고일은 2024. 09. 23.입니다. 이는 청약자격의 판단기준일입니다.")])], 1)], 1)], 1)], 1), _c('sheet-secondary', {
    staticClass: "my-16px my-md-20px"
  }, [_c('txt-quarternary', [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("①")]), _c('v-col', [_vm._v(" 소득 기준 순위 "), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("- 1순위")]), _c('v-col', [_vm._v(": 기준소득 100% 이하")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("- 2순위")]), _c('v-col', [_vm._v(": 기준소득 110% 이하")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("- 3순위")]), _c('v-col', [_vm._v(": 기준소득 120% 이하")])], 1)], 1)], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("②")]), _c('v-col', [_vm._v(" 지역 기준 순위 : 동일 소득일 경우 거주지역 우선 (대학 및 직장 소재지 포함) "), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("- 1순위")]), _c('v-col', [_vm._v(": 해당 공급 대상 주택 소재지(서울특별시 은평구)")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("- 2순위")]), _c('v-col', [_vm._v(": 해당 공급 대상 주택 소재지 외(서울특별시)")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("- 3순위")]), _c('v-col', [_vm._v(": 그 외 지역")])], 1)], 1)], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("*")]), _c('v-col', [_vm._v("동일 순위 경쟁 시 “소득순위 → 지역순위 → 추첨” 순으로 선정 됩니다.")])], 1)], 1)], 1), _c('txt-quarternary', [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_vm._v("특별공급은 각각의 신청자격을 모두 충족하는 자를 대상으로 주택형(타입) 별로 접수하여, 상기 소득 및 해당 지역의 순위에 따라 당첨자를 선정하고 동일 순위 발생 시 공정한 방식에 의한 무작위 추첨으로 당첨자를 선정하며, 예비입주자 및 예비순번 역시 무작위 추첨으로 결정됩니다.")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_vm._v("당첨자 선정 후 동·호수 결정(동·호수 변경은 불가) 또한 공정한 방식에 의한 무작위 추첨으로 당첨자를 선정합니다.")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_vm._v("입주자격 조사결과에 따른 ‘부적격사유’에 대한 소명 의무는 전적으로 신청자에게 있습니다.")])], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "page-section__content"
  }, [_c('u-tit-wrap-default', {
    staticClass: "tit-wrap--xs"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('tit-tertiary', [_vm._v("일반공급대상자")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-chip-group', [_c('chip-secondary', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("1인가구(청년, 대학생)")])], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "list-item"
  }, [_c('txt-quarternary', [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "font-weight-bold grey--text text--darken-4"
  }, [_vm._v("1)")])]), _c('v-col', [_c('span', {
    staticClass: "font-weight-bold grey--text text--darken-4"
  }, [_vm._v("청년 계층 신청자격")]), _vm._v(" : 입주자모집공고일(2024. 09. 23) 현재 아래의 요건을 모두 갖춘 자(외국인제외) "), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("※")]), _c('v-col', [_vm._v("본 공고문의 모집 공고일은 2024. 09. 23.입니다. 이는 청약자격의 판단기준일입니다.")])], 1)], 1)], 1)], 1), _c('sheet-secondary', {
    staticClass: "my-16px my-md-20px"
  }, [_c('txt-quarternary', [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("①")]), _c('v-col', [_vm._v("만 19세 이상 만 39세 이하인 자 (1984년 09월 24일부터 2005년 09월 23일 사이에 출생자)")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("②")]), _c('v-col', [_vm._v("미혼")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("③")]), _c('v-col', [_vm._v("무주택자")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("④")]), _c('v-col', [_vm._v("소득 및 자산, 지역 요건 없음")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("⑤")]), _c('v-col', [_vm._v("자동차(이륜차 포함) 무소유·미운행자 또는 2024년 기준 자동차가액 3,708만 원 이내의 자동차(이륜차 포함) 소유·운행자")])], 1)], 1)], 1), _c('txt-quarternary', [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_vm._v("신청자 본인은 무주택자여야 하며, 세대 내 다른 구성원이 주택을 소유해도 신청 가능합니다.")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_vm._v("‘무주택자’란 주택을 소유하고 있지 않은 자를 말합니다.")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("※")]), _c('v-col', [_vm._v("자동차가액 기준은 공공주택 입주자 자산기준(공공주택 입주자 보유자산 관련 업무처리기준 (현행행정규칙)) 준용합니다. (단, 적용 가액은 자동차 소유 지분, 유자녀(출생일 기준), 장애인 및 국가유공자, 자동차 보유 대수, 저공해자동차(보조금 제외)에 따라 다르오니, 지원 전 공공주택 입주자 자산기준(공공주택 입주자 보유자산 관련 업무처리기준(현행행정규칙)) 반드시 확인하시기 바랍니다.)")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("※")]), _c('v-col', [_vm._v(" “자격 및 자산 자동차가액 판단”과 “등록(사용) 자동차가액 판단”의 차이 "), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("자격 및 자산 자동차가액 판단 : 기준 가액 이하")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("등록(사용) 자동차가액 판단 : 기준 가액 이하만 등록하여 사용 가능 (단, 등록(사용) 할 자동차가액 판단 시 지분 소유는 미인정. 등록(사용) 할 자동차 전체 자동차가액으로만 판단.)")])], 1)], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "list-item"
  }, [_c('txt-quarternary', [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "font-weight-bold grey--text text--darken-4"
  }, [_vm._v("2)")])]), _c('v-col', [_c('span', {
    staticClass: "font-weight-bold grey--text text--darken-4"
  }, [_vm._v("(예비)신혼부부 계층 신청자격")]), _vm._v(" : 입주자모집공고일 2024. 09. 23. 현재 아래의 요건을 모두 갖춘 자(외국인제외) "), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("※")]), _c('v-col', [_vm._v("본 공고문의 모집 공고일은 2024. 09. 23.입니다. 이는 청약자격의 판단기준일입니다.")])], 1)], 1)], 1)], 1), _c('sheet-secondary', {
    staticClass: "my-16px my-md-20px"
  }, [_c('txt-quarternary', [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("①")]), _c('v-col', [_vm._v("만 19세 이상 만 39세 이하인 자 (1984년 09월 24일부터 2005년 09월 23일 사이에 출생자, 신청자만 해당)")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("②")]), _c('v-col', [_vm._v("신혼부부는 혼인중인 자, 예비신혼부부는 해당 주택의 입주 전까지 혼인사실을 증명할 수 있는 자")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("③")]), _c('v-col', [_vm._v("신혼부부의 경우 "), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("“무주택세대구성원”")]), _vm._v(", 예비신혼부부의 경우 각각 무주택자")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("④")]), _c('v-col', [_vm._v("소득 및 자산, 지역 요건 없음")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("⑤")]), _c('v-col', [_vm._v("자동차(이륜차 포함) 무소유·미운행자 또는 2024년 기준 자동차가액 3,708만 원 이내의 자동차(이륜차 포함) 소유·운행자")])], 1)], 1)], 1), _c('txt-quarternary', [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_vm._v("신혼부부는 혼인 후 7년이내(혼인 후 2,555일 까지, 2017.09.25. ~ 2024.09.23. 까지)의 부부를 말합니다.")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_vm._v("신혼부부(예비신혼부부 포함)는 1세대로 간주하여 부부(예비부부) 중 1명이 대표로 1건만 신청이 가능하고, 두 명이 각각 중복 신청할 경우에는 전부 무효 처리됩니다.")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_vm._v("신혼부부(예비신혼부부 포함)형은 청약을 신청한 당첨자 명의로 계약 체결이 가능하며, 부부공동명의 계약은 불가합니다.")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_vm._v(" “무주택세대구성원”이란 전원이 주택을 소유하고 있지 않은 세대의 구성원을 말하며, 이때의 ‘세대’란 다음의 사람으로 구성된 집단(신청자가 세대별 주민등록표에 등재되어 있지 않은 경우는 제외)을 말합니다. "), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("①")]), _c('v-col', [_vm._v("신청자 본인")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("②")]), _c('v-col', [_vm._v("신청자의 배우자")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("③")]), _c('v-col', [_vm._v("신청자의 직계존속(신청자 배우자의 직계존속 포함)으로서 신청자 또는 신청자의 배우자와 동일 세대 등재자")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("④")]), _c('v-col', [_vm._v("신청자의 직계비속(직계비속의 배우자 포함)으로서 신청자 또는 신청자 배우자와 동일 세대 등재자")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("⑤")]), _c('v-col', [_vm._v("신청자의 배우자의 직계비속으로서 신청자와 동일 세대 등재자")])], 1)], 1)], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_vm._v("‘무주택자’란 주택을 소유하고 있지 않은 자를 말합니다.")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("※")]), _c('v-col', [_vm._v("자동차가액 기준은 공공주택 입주자 자산기준(공공주택 입주자 보유자산 관련 업무처리기준 (현행행정규칙)) 준용합니다. (단, 적용 가액은 자동차 소유 지분, 유자녀(출생일 기준), 장애인 및 국가유공자, 자동차 보유 대수, 저공해자동차(보조금 제외)에 따라 다르오니, 지원 전 공공주택 입주자 자산기준(공공주택 입주자 보유자산 관련 업무처리기준(현행행정규칙)) 반드시 확인하시기 바랍니다.)")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("※")]), _c('v-col', [_vm._v(" “자격 및 자산 자동차가액 판단”과 “등록(사용) 자동차가액 판단”의 차이 "), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("자격 및 자산 자동차가액 판단 : 기준 가액 이하")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("·")]), _c('v-col', [_vm._v("등록(사용) 자동차가액 판단 : 기준 가액 이하만 등록하여 사용 가능 (단, 등록(사용) 할 자동차가액 판단 시 지분 소유는 미인정. 등록(사용) 할 자동차 전체 자동차가액으로만 판단.)")])], 1)], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "list-item"
  }, [_c('txt-quarternary', [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "font-weight-bold grey--text text--darken-4"
  }, [_vm._v("3)")])]), _c('v-col', [_c('span', {
    staticClass: "font-weight-bold grey--text text--darken-4"
  }, [_vm._v("일반공급 선정기준 및 방법")]), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("※")]), _c('v-col', [_vm._v(" 본 공고문의 모집 공고일은 2024. 09. 23. 입니다. 이는 청약자격의 판단기준일입니다. "), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_vm._v("일반공급은 각각의 신청자격을 모두 충족하는 자를 대상으로 주택형(타입) 별로 접수하여, 공정한 방식에 의한 무작위 추첨으로 당첨자를 선정하며, 예비입주자 및 예비순번 역시 무작위 추첨으로 결정됩니다.")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_vm._v("당첨자 선정 후 동·호수 결정(동·호수 변경은 불가) 또한 공정한 방식에 의한 무작위 추첨으로 당첨자를 선정합니다.")])], 1), _c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', [_vm._v("입주자격 조사결과에 따른 ‘부적격사유’에 대한 소명 의무는 전적으로 신청자에게 있습니다.")])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }