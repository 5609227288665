<template>
    <v-sheet class="unit-content">
        <div class="v-data-table v-data-table--border v-data-table--line v-data-table--density-comfortable">
            <div class="v-data-table__wrapper">
                <table>
                    <thead>
                        <tr>
                            <th-primary>TYPE</th-primary>
                            <th-primary>전용면적</th-primary>
                            <th-primary>공용면적</th-primary>
                            <th-primary>공급면적</th-primary>
                            <th-primary>주차장 등 공용면적</th-primary>
                            <th-primary>계약면적</th-primary>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- S: A TYPE -->
                        <tr v-if="$route.hash == '#a' || $route.hash == ''">
                            <th-secondary>A TYPE</th-secondary>
                            <td-primary>17.65㎡</td-primary>
                            <td-primary>12.70㎡</td-primary>
                            <td-primary>30.35㎡</td-primary>
                            <td-primary>4.61㎡</td-primary>
                            <td-primary>34.96㎡</td-primary>
                        </tr>
                        <!-- E: A TYPE -->

                        <!-- S: B TYPE -->
                        <tr v-if="$route.hash == '#b'">
                            <th-secondary>B TYPE</th-secondary>
                            <td-primary>35.84㎡</td-primary>
                            <td-primary>24.78㎡</td-primary>
                            <td-primary>60.62㎡</td-primary>
                            <td-primary>9.35㎡</td-primary>
                            <td-primary>69.97㎡</td-primary>
                        </tr>
                        <!-- E: B TYPE -->

                        <!-- S: C TYPE -->
                        <tr v-if="$route.hash == '#c'">
                            <th-secondary>C TYPE</th-secondary>
                            <td-primary>35.14㎡</td-primary>
                            <td-primary>24.07㎡</td-primary>
                            <td-primary>59.21㎡</td-primary>
                            <td-primary>9.17㎡</td-primary>
                            <td-primary>68.38㎡</td-primary>
                        </tr>
                        <!-- E: C TYPE --> 

                        <!-- S: D TYPE -->
                        <tr v-if="$route.hash == '#d'">
                            <th-secondary>D TYPE</th-secondary>
                            <td-primary>17.02㎡</td-primary>
                            <td-primary>12.24㎡</td-primary>
                            <td-primary>29.26㎡</td-primary>
                            <td-primary>4.44㎡</td-primary>
                            <td-primary>33.70㎡</td-primary>
                        </tr>
                        <!-- E: D TYPE -->

                        <!-- S: E TYPE -->
                        <tr v-if="$route.hash == '#e'">
                            <th-secondary>E TYPE</th-secondary>
                            <td-primary>17.54㎡</td-primary>
                            <td-primary>12.56㎡</td-primary>
                            <td-primary>30.1㎡</td-primary>
                            <td-primary>4.58㎡</td-primary>
                            <td-primary>34.68㎡</td-primary>
                        </tr>
                        <!-- E: E TYPE -->

                        <!-- S: G TYPE -->
                        <tr v-if="$route.hash == '#g'">
                            <th-secondary>G TYPE</th-secondary>
                            <td-primary>36.39㎡</td-primary>
                            <td-primary>25.45㎡</td-primary>
                            <td-primary>61.84㎡</td-primary>
                            <td-primary>9.50㎡</td-primary>
                            <td-primary>71.34㎡</td-primary>
                        </tr>
                        <!-- E: G TYPE -->

                        <!-- S: H TYPE -->
                        <tr v-if="$route.hash == '#h'">
                            <th-secondary>H TYPE</th-secondary>
                            <td-primary>39.03㎡</td-primary>
                            <td-primary>26.82㎡</td-primary>
                            <td-primary>65.85㎡</td-primary>
                            <td-primary>10.19㎡</td-primary>
                            <td-primary>76.04㎡</td-primary>
                        </tr>
                        <!-- E: H TYPE -->
                    </tbody>
                </table>
            </div>
        </div>
    </v-sheet>
</template>

<script>
import ThPrimary from "@/components/publish/parents/tables/th-primary.vue";
import ThSecondary from "@/components/publish/parents/tables/th-secondary.vue";
import TdPrimary from "@/components/publish/parents/tables/td-primary.vue";

export default {
    props: {
        type: { type: String, default: "" },
        area2: { type: String, default: "" },
        area3: { type: String, default: "" },
    },
    components: {
        ThPrimary,
        ThSecondary,
        TdPrimary,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
