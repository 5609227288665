<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="단지안내" tabActive="층별 호수 배치도" bg="/images/sub/complex/visual.jpg">
                <template #title>단지안내</template>
            </sub-head-primary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first page-section--last">
            <template #tit>층별 호수 배치도</template>

            <div class="page-section__content">
                <v-tabs grow hide-slider centered v-model="tab" class="v-tabs--density-compact">
                    <tab-quarternary>3층</tab-quarternary>
                    <tab-quarternary>4층</tab-quarternary>
                    <tab-quarternary>5층</tab-quarternary>
                    <tab-quarternary>6층</tab-quarternary>
                    <tab-quarternary>7~21층</tab-quarternary>
                    <tab-quarternary>22층</tab-quarternary>
                </v-tabs>
            </div>

            <div class="page-section__content">
                <v-tabs-items v-model="tab">
                    <v-tab-item eager>
                        <sheet-secondary class="py-md-60px" align="center">
                            <v-row>
                                <v-col cols="12"><img src="/images/sub/complex/floor/floor-3.jpg" alt="" /></v-col>
                                <v-col cols="12">
                                    <v-card flat tile color="grey lighten-4" max-width="800" class="py-46px">
                                        <u-tit-default class="tit--xxs">통일로 30m 도로</u-tit-default>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </sheet-secondary>
                    </v-tab-item>
                    <v-tab-item eager>
                        <sheet-secondary class="py-md-60px" align="center">
                            <v-row>
                                <v-col cols="12"><img src="/images/sub/complex/floor/floor-4.jpg" alt="" /></v-col>
                                <v-col cols="12">
                                    <v-card flat tile color="grey lighten-4" max-width="800" class="py-46px">
                                        <u-tit-default class="tit--xxs">통일로 30m 도로</u-tit-default>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </sheet-secondary>
                    </v-tab-item>
                    <v-tab-item eager>
                        <sheet-secondary class="py-md-60px" align="center">
                            <v-row>
                                <v-col cols="12"><img src="/images/sub/complex/floor/floor-5.jpg" alt="" /></v-col>
                                <v-col cols="12">
                                    <v-card flat tile color="grey lighten-4" max-width="800" class="py-46px">
                                        <u-tit-default class="tit--xxs">통일로 30m 도로</u-tit-default>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </sheet-secondary>
                    </v-tab-item>
                    <v-tab-item eager>
                        <sheet-secondary class="py-md-60px" align="center">
                            <v-row>
                                <v-col cols="12"><img src="/images/sub/complex/floor/floor-6.jpg" alt="" /></v-col>
                                <v-col cols="12">
                                    <v-card flat tile color="grey lighten-4" max-width="800" class="py-46px">
                                        <u-tit-default class="tit--xxs">통일로 30m 도로</u-tit-default>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </sheet-secondary>
                    </v-tab-item>
                    <v-tab-item eager>
                        <sheet-secondary class="py-md-60px" align="center">
                            <v-row>
                                <v-col cols="12"><img src="/images/sub/complex/floor/floor-7-21.jpg" alt="" /></v-col>
                                <v-col cols="12">
                                    <v-card flat tile color="grey lighten-4" max-width="800" class="py-46px">
                                        <u-tit-default class="tit--xxs">통일로 30m 도로</u-tit-default>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </sheet-secondary>
                    </v-tab-item>
                    <v-tab-item eager>
                        <sheet-secondary class="py-md-60px" align="center">
                            <v-row>
                                <v-col cols="12"><img src="/images/sub/complex/floor/floor-22.jpg" alt="" /></v-col>
                                <v-col cols="12">
                                    <v-card flat tile color="grey lighten-4" max-width="800" class="py-46px">
                                        <u-tit-default class="tit--xxs">통일로 30m 도로</u-tit-default>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </sheet-secondary>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </page-section-primary>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import TabQuarternary from "@/components/publish/parents/tabs/tab-quarternary.vue";
import SheetSecondary from "@/components/publish/parents/sheets/sheet-secondary.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        TabQuarternary,
        SheetSecondary,
        UTitDefault,
    },
    data() {
        return {
            tab: null,
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.v-tab--button {
    --tab-margin-compact: 6px;
}
::v-deep {
    .v-slide-group__content {
        flex-wrap: wrap !important;
    }
}
.v-tabs--grow > .v-tabs-bar .v-tab {
    flex: 0 0 calc(33.33% - 8px) !important;
    justify-content: center;
    &:first-child,
    &:nth-child(4) {
        margin-left: 0 !important;
    }
    &:last-child,
    &:nth-child(3) {
        margin-right: 0 !important;
    }
}
@media (min-width: 768px) {
    .v-tab--button {
        --tab-margin-compact: 0 6px;
    }
    ::v-deep {
        .v-slide-group__content {
            flex-wrap: wrap !important;
            justify-content: center;
            margin: 0;
        }
    }
    .v-tabs--grow > .v-tabs-bar .v-tab {
        width: initial;
        flex: 1 1 !important;
        &:nth-child(3),
        &:nth-child(4) {
            margin: var(--tab-margin-compact) !important;
        }
    }
}
</style>
