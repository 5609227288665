<template>
    <app-primary>
        <template #contentsHead>
            <sub-head-primary itemTitle="단지안내" tabActive="근린생활시설 평면도" bg="/images/sub/complex/visual.jpg">
                <template #title>단지안내</template>
            </sub-head-primary>
        </template>

        <page-section-primary titWrapClass="text-center" class="page-section--first page-section--last">
            <template #tit>근린생활시설 평면도</template>

            <div class="page-section__content">
                <v-tabs grow hide-slider centered v-model="tab" class="v-tabs--density-compact">
                    <tab-quarternary>지하 2층</tab-quarternary>
                    <tab-quarternary>지상 1층</tab-quarternary>
                    <tab-quarternary>지상 2층</tab-quarternary>
                </v-tabs>
            </div>

            <div class="page-section__content">
                <v-tabs-items v-model="tab">
                    <v-tab-item eager>
                        <sheet-secondary class="py-md-106px" align="center">
                            <img src="/images/sub/complex/neighborhood/b1.jpg" alt="">
                        </sheet-secondary>
                    </v-tab-item>
                    <v-tab-item eager>
                        <sheet-secondary class="py-md-106px" align="center">
                            <img src="/images/sub/complex/neighborhood/f1.jpg" alt="">
                        </sheet-secondary>
                    </v-tab-item>
                    <v-tab-item eager>
                        <sheet-secondary class="py-md-106px" align="center">
                            <img src="/images/sub/complex/neighborhood/f2.jpg" alt="">
                        </sheet-secondary>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </page-section-primary>
    </app-primary>
</template>

<script>
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import SubHeadPrimary from "@/sets/parents/pages/sub-head-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import TabQuarternary from "@/components/publish/parents/tabs/tab-quarternary.vue";
import SheetSecondary from "@/components/publish/parents/sheets/sheet-secondary.vue";

export default {
    props: {},
    components: {
        AppPrimary,
        SubHeadPrimary,
        PageSectionPrimary,
        TabQuarternary,
        SheetSecondary,
    },
    data() {
        return {
            tab: null,
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.v-tabs {
    max-width: 716px;
    margin: auto;
}
</style>
