var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "사업안내",
            "tabActive": "사업개요",
            "bg": "/images/sub/business/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("사업안내")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "titWrapClass": "text-center"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("사업개요")];
      },
      proxy: true
    }])
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/business/overview/overview.jpg"
    }
  }), _c('table-primary', {
    staticClass: "overview-table",
    attrs: {
      "items": _vm.items,
      "headers": _vm.headers
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var items = _ref.items;
        return [_c('tbody', _vm._l(_vm.headers, function (header) {
          return _c('tr', {
            key: header.text
          }, [_c('th-tertiary', [_vm._v(_vm._s(header.text))]), _vm._l(items, function (item) {
            return _c('td-secondary', {
              key: item.text
            }, [_vm._v(_vm._s(item[header.value]))]);
          })], 2);
        }), 0)];
      }
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }