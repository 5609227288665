var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    scopedSlots: _vm._u([{
      key: "contentsHead",
      fn: function () {
        return [_c('sub-head-primary', {
          attrs: {
            "itemTitle": "청약안내",
            "tabActive": "제출서류",
            "bg": "/images/sub/subscription/visual.jpg"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function () {
              return [_vm._v("청약안내")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }, [_c('page-section-primary', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "titWrapClass": "text-center"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("LUMINO 816 청약신청 제출서류")];
      },
      proxy: true
    }])
  }, [_c('v-row', [_vm._l(_vm.items, function (item, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "md": "4"
      }
    }, [_c('v-img', {
      staticClass: "document",
      attrs: {
        "src": item.image,
        "max-width": "384"
      }
    }, [_c('div', {
      staticClass: "document__caption"
    }, [_vm._v(" " + _vm._s(item.name) + " ")])])], 1)];
  })], 2), _c('div', {
    staticClass: "btn-wrap btn-wrap--lg"
  }, [_c('btn-primary', {
    attrs: {
      "x-large": "",
      "href": "/assets/document/documents.zip",
      "download": "LUMINO 816 청약신청 제출서류.zip"
    }
  }, [_vm._v(" 제출서류 다운로드 "), _c('v-img', {
    staticClass: "ml-16px ml-lg-24px",
    attrs: {
      "src": "/images/icon/icon-download-white.png"
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }