<template>
    <v-sheet class="outlined-sheet" outlined><slot /></v-sheet>
</template>

<script>
export default {
    props: {
    },
    components: {
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.outlined-sheet {
    padding: 12px;
    border-color: var(--v-grey-lighten4);
    &--border {
        border-width: 2px;
    }
    &--y {
        border-width: 2px;
        border-left: 0;
        border-right: 0;
    }
}
@media (min-width: 768px) {
    .outlined-sheet {
        padding: 24px;
    }
}
</style>